import axios from "axios";
import { ethers } from "ethers";
import { useMemo } from "react";
import { getServerUrl } from "src/helpers/Helpers";
import { useSingleCallResult } from "src/lib/hooks/multicall";
import { IBaseAsyncThunk } from "src/slices/interfaces";
import { compareAddress } from "src/utils/address";
import { BN } from "src/utils/bn";
import useSWR from "swr";

import { abi as PairABI } from "../abis/Pair.json";
import { addresses } from "../configs/constants";
import { usePairContract, useTokenContract } from "./useContract";
import { useWeb3Context } from "./web3Context";

export async function getEdePrice({ networkID, provider }: IBaseAsyncThunk) {
  const pairContract = new ethers.Contract(addresses[networkID].EDE_LP, PairABI, provider);
  const token0 = await pairContract.token0();
  const token0Contract = new ethers.Contract(token0, PairABI, provider);
  const decimal0 = await token0Contract.decimals();
  const token1 = await pairContract.token1();
  const token1Contract = new ethers.Contract(token1, PairABI, provider);
  const decimal1 = await token1Contract.decimals();
  const reserves = await pairContract.getReserves();
  let edePrice;
  if (token0.toString().toLowerCase() == addresses[networkID].EDE.toLowerCase()) {
    edePrice = (reserves[1] / reserves[0]) * Math.pow(10, decimal0 - decimal1);
  } else {
    edePrice = (reserves[0] / reserves[1]) * Math.pow(10, decimal1 - decimal0);
  }

  if (networkID == 42161) {
    // || networkID == 421613
    const indexPricesUrl = getServerUrl(networkID, "/prices");
    const res = await axios.get(indexPricesUrl);
    const ethPrice = Number(ethers.utils.formatUnits(res.data.ETHUSDT, 30));
    return edePrice * ethPrice;
  }

  return edePrice;
}

export function useGetEdePrice() {
  const { chainID } = useWeb3Context();

  const pairAddre = addresses[chainID]?.EDE_LP;
  const EDEAddress = addresses[chainID]?.EDE;
  const pairContract = usePairContract(pairAddre);
  const { result: Token0 } = useSingleCallResult(pairContract, "token0");
  const { result: Token1 } = useSingleCallResult(pairContract, "token1");
  const { result: pairReserves } = useSingleCallResult(pairContract, "getReserves");
  const { token0, token1, reserves } = useMemo(() => {
    if (!Token0 || !Token1 || !pairReserves) return {};
    return {
      token0: Token0[0] as string,
      token1: Token1[0] as string,
      reserves: pairReserves,
    };
  }, [Token0, Token1, pairReserves]);
  const token0Contract = useTokenContract(token0);
  const token1Contract = useTokenContract(token1);
  const { result: Token0Decimals } = useSingleCallResult(token0Contract, "decimals");
  const { result: Token1Decimals } = useSingleCallResult(token1Contract, "decimals");
  const edePrice = useMemo(() => {
    if (!reserves || !Token0Decimals || !Token1Decimals) return;
    // if (compareAddress(token0, EDEAddress)) {
    //   // return reserves[0].div(reserves[1]).pow(BN(Token0Decimals[0]).sub(Token0Decimals[1]));
    // } else {
    //   return reserves[1].div(reserves[0]).pow(Token0Decimals[1].sub(Token0Decimals[0]));
    // }
  }, [reserves, token0, Token0Decimals, Token1Decimals]);
}
