import { ethers } from "ethers";
import { getEdePrice } from "src/hooks/getPrice_ede";
import { abi as ElpManagerAbi } from "../abis/ElpManager.json";
import { abi as ElpABI } from "../abis/ELP.json";
import { abi as RewardTrackerABI } from "../abis/RewardTracker.json";
import { toolNumber } from "src/helpers/numberHelper";

function sum(arr) {
  var s = 0;
  for (var i = arr.length - 1; i >= 0; i--) {
    s += arr[i];
  }
  return s;
}

const get_apr_eusd_elp = async (ELPContract, totalSupply, price) => {
  const feeAmount1 = await ELPContract.getFeeAmount(7, 1);
  const feeAmount2 = await ELPContract.getFeeAmount(1, 1);
  const feeAmount = feeAmount1.gt(feeAmount2) ? feeAmount1 : feeAmount2;
  // apr_eusd_elp = (Number(ethers.utils.formatUnits(feeAmount2, 18)) / 1 * 365) / (1 * totalSupply * price) * 0.6;
  return (Number(ethers.utils.formatUnits(feeAmount, 18)) / 7 * 365) / (1 * totalSupply * price) * 0.6;
}

export const getTokensArr = async (
  name,
  top,
  feeAmount,
  totalStaked_elp,
  stakingPool_elp,
  elpAddress,
  ElpManagerAddress,
  RewardTrackerAddress,
  tokens,
  networkID,
  provider,
  address) => {
  const edePrice = await getEdePrice({ networkID, provider });
  let elpTokenArr = [];
  const ElpManagerContract = await new ethers.Contract(ElpManagerAddress, ElpManagerAbi, provider);
  const poolInfo = await ElpManagerContract.getPoolInfo();
  const totalSupply = Number(ethers.utils.formatUnits(poolInfo[2], 18));
  const marketCap = Number(ethers.utils.formatUnits(poolInfo[0], 30));
  const price = poolInfo[0] / poolInfo[2] / Math.pow(10, 12);
  const usdxSupply = poolInfo[3]; // 18
  const tokenList = await ElpManagerContract.getPoolTokenList();
  // console.log("tokenList--",  tokenList)
  let poolArr = [];
  for (let i = 0; i < tokenList.length; i++) {
    const currTokenArr = tokens.filter((token) => tokenList[i] == token.address);
    const currToken = currTokenArr[0];
    const tokenInfo = await ElpManagerContract.getPoolTokenInfo(tokenList[i]);
    const poolNum = Number(ethers.utils.formatUnits(tokenInfo[2], currToken.decimals));
    const price = Number(ethers.utils.formatUnits(tokenInfo[3], 30));
    const pool = poolNum * price;
    poolArr.push(pool);
  }
  const poolSum = sum(poolArr);
  let chainInfoArr = [];
  for (let i = 0; i < tokenList.length; i++) {
    const currTokenArr = tokens.filter((token) => tokenList[i] == token.address);
    const currToken = currTokenArr[0];
    const tokenInfo = await ElpManagerContract.getPoolTokenInfo(tokenList[i]);
    const tokenWeight = ethers.utils.formatUnits(tokenInfo[0], 6);
    const utilization = ethers.utils.formatUnits(tokenInfo[1], 6);
    const poolNum = Number(ethers.utils.formatUnits(tokenInfo[2], currToken.decimals));
    const price = Number(ethers.utils.formatUnits(tokenInfo[3], 30));
    const poolAmount = poolNum * price;
    const tokenWeight2 = poolAmount / poolSum;//marketCap;
    chainInfoArr.push({ address: tokenList[i], price, tokenWeight, tokenWeight2: toolNumber(tokenWeight2), utilization, poolNum, poolAmount, ...currToken });
  }
  const apr_ede_elp = (stakingPool_elp * 3600 * 24 * 365 * edePrice) / (totalStaked_elp * price);
  const ELPContract = new ethers.Contract(elpAddress, ElpABI, provider);

  let apr_eusd_elp = 0
  if (networkID == 42161 || networkID == 421613) {
    apr_eusd_elp = await get_apr_eusd_elp(ELPContract, totalSupply, price)
  } else {
    if (name == "ELP-1p") {
      apr_eusd_elp = await get_apr_eusd_elp(ELPContract, totalSupply, price)
    } else {
      apr_eusd_elp = (feeAmount * 365) / (1 * totalSupply * price);
    }
  }

  const rewardTrackerContract = new ethers.Contract(RewardTrackerAddress, RewardTrackerABI, provider);
  let elpBalance = 0
  let elpBalanceStaked = 0
  if (address) {
    elpBalance = ethers.utils.formatEther(await ELPContract.balanceOf(address));
    elpBalanceStaked = ethers.utils.formatEther(await rewardTrackerContract.balanceOf(address));
  }
  elpTokenArr.push({
    name,
    top,
    address: elpAddress,
    price,
    totalStaked_elp,
    marketCap,
    totalSupply,
    chainInfoArr,
    usdxSupply,
    tokenList,
    apr_ede_elp,
    apr_eusd_elp,
    apr: apr_ede_elp + apr_eusd_elp,
    elpBalance,
    elpBalanceStaked,
  });
  return elpTokenArr;
};