import { getContractAddress } from "src/Addresses";

export const getVaultAddress = (chainID, elpName) => {
  let vaultAddress1 = getContractAddress(chainID, "Vault");
  if (elpName == 'ELP-2') {
    vaultAddress1 = getContractAddress(chainID, "Vault_ELP_2");
  } else if (elpName == 'ELP-1p') {
    vaultAddress1 = getContractAddress(chainID, "Vault_ELP_1p");
  }
  return vaultAddress1
}

export const getPositionRouterAddress = (chainID, elpName) => {
  let positionRouterAddress1 = getContractAddress(chainID, "PositionRouter");

  if (elpName == 'ELP-2') {
    positionRouterAddress1 = getContractAddress(chainID, "PositionRouter_ELP_2");
  } else if (elpName == 'ELP-1p') {
    positionRouterAddress1 = getContractAddress(chainID, "PositionRouter_ELP_1p");
  }
  return positionRouterAddress1
}

export const getOrderBookAddress = (chainID, elpName) => {
  let orderBookAddress1 = getContractAddress(chainID, "OrderBook");
  if (elpName == 'ELP-2') {
    orderBookAddress1 = getContractAddress(chainID, "OrderBook_ELP_2");
  } else if (elpName == 'ELP-1p') {
    orderBookAddress1 = getContractAddress(chainID, "OrderBook_ELP_1p");
  }
  return orderBookAddress1
}

export const getRouterAddress = (chainID, elpName) => {
  let routerAddress1 = getContractAddress(chainID, "Router");
  if (elpName == 'ELP-2') {
    routerAddress1 = getContractAddress(chainID, "Router_ELP_2");
  } else if (elpName == 'ELP-1p') {
    routerAddress1 = getContractAddress(chainID, "Router_ELP_1p");
  }
  return routerAddress1
}

export const getPositionManagerAddress = (chainID, elpName) => {
  let positionManagerAddress1 = getContractAddress(chainId, "PositionManager");

  if (elpName == 'ELP-2') {
    positionManagerAddress1 = getContractAddress(chainID, "PositionManager_ELP_2");
  } else if (elpName == 'ELP-1p') {
    positionManagerAddress1 = getContractAddress(chainID, "PositionManager_ELP_1p");
  }
  return positionManagerAddress1
}

export const getElpAddress = (chainID, elpName) => {
  let Elp1Address = getContractAddress(chainID, "ELP");
  if (elpName == 'ELP-2') {
    Elp1Address = getContractAddress(chainID, "ELP_2");
  } else if (elpName == 'ELP-1p') {
    Elp1Address = getContractAddress(chainID, "ELP_1p");
  }
  return Elp1Address
}

export const getElpManagerAddress = (chainID, elpName) => {
  let elpManagerAddress1 = getContractAddress(chainID, "ElpManager");
  if (elpName == 'ELP-2') {
    elpManagerAddress1 = getContractAddress(chainID, "ElpManager_ELP_2");
  } else if (elpName == 'ELP-1p') {
    elpManagerAddress1 = getContractAddress(chainID, "ElpManager_ELP_1p");
  }
  return elpManagerAddress1
}