import { ethers } from "ethers";
import { getContractAddress } from "../Addresses";

import BTC_icon from "../assets/images/tokens/BTC.svg";
import BNB_icon from "../assets/images/tokens/BNB.svg";
import BUSD_icon from "../assets/images/tokens/BUSD.svg";
import ETH_icon from "../assets/images/tokens/ETH.svg";
import ADA_icon from "../assets/images/tokens/ADA.svg";
import CAKE_icon from "../assets/images/tokens/CAKE.svg";
import DOGE_icon from "../assets/images/tokens/DOGE.svg";
import LINK_icon from "../assets/images/tokens/LINK.svg";
import XRP_icon from "../assets/images/tokens/XRP.svg";
import USDC_icon from "../assets/images/tokens/USDC.svg";
import USDT_icon from "../assets/images/tokens/USDT.svg";
import DAI_icon from "../assets/images/tokens/DAI.svg";

const TOKENS = {
  97: [
    {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
      isNative: true,
      address: ethers.constants.AddressZero,
      imageUrl: BNB_icon,
      isShortable: true,
      isLongable: true,
      isElp1: true,
      tokenDecimals: 2,
    },
    {
      name: "BNB (Wrapped)",
      symbol: "WBNB",
      baseSymbol: "BNB",
      decimals: 18,
      address: getContractAddress(97, "WBNB"),
      imageUrl: BNB_icon,
      isShortable: false,
      isWrapped: true,
      isElp1: true,
      tokenDecimals: 2,
    },
    {
      name: "Binance USD",
      symbol: "BUSD",
      decimals: 18,
      address: getContractAddress(97, "BUSD"),
      imageUrl: BUSD_icon,
      isShortable: true,
      isStable: true,
      isElp1: true,
      isElp2: true,
      tokenDecimals: 2,
    },
    {
      name: "Bitcoin",
      symbol: "BTC",
      decimals: 18,
      address: getContractAddress(97, "BTC"),
      imageUrl: BTC_icon,
      isShortable: true,
      isLongable: true,
      isElp1: true,
      tokenDecimals: 2,
    },
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: getContractAddress(97, "ETH"),
      imageUrl: ETH_icon,
      isShortable: true,
      isLongable: true,
      isElp1: true,
      tokenDecimals: 2,
    },
    // {
    //   name: "Dogecoin",
    //   symbol: "DOGE",
    //   decimals: 18,
    //   address: getContractAddress(97, "DOGE"),
    //   imageUrl: DOGE_icon,
    //   isElp2: true,
    //   isLongable: true,
    //   isShortable: true,
    //   tokenDecimals: 4,
    // },
    {
      name: "Cardano",
      symbol: "ADA",
      decimals: 18,
      address: getContractAddress(97, "ADA"),
      imageUrl: ADA_icon,
      isLongable: true,
      isShortable: true,
      isElp2: true,
      tokenDecimals: 4,
    },
    {
      name: "XRP",
      symbol: "XRP",
      decimals: 18,
      address: getContractAddress(97, "XRP"),
      imageUrl: XRP_icon,
      isShortable: true,
      isLongable: true,
      isElp2: true,
      tokenDecimals: 4,
    },
    {
      name: "Chainlink",
      symbol: "LINK",
      decimals: 18,
      address: getContractAddress(97, "LINK"),
      imageUrl: LINK_icon,
      isShortable: true,
      isLongable: true,
      isElp2: true,
      tokenDecimals: 4,
    },
    {
      name: "PancakeSwap",
      symbol: "CAKE",
      decimals: 18,
      address: getContractAddress(97, "CAKE"),
      imageUrl: CAKE_icon,
      isShortable: true,
      isLongable: true,
      isElp2: true,
      tokenDecimals: 4,
    },
  ],
  56: [
    {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
      isNative: true,
      address: ethers.constants.AddressZero,
      imageUrl: BNB_icon,
      isShortable: true,
      isLongable: true,
      isElp1: true,
      isElp1p: true,
      tokenDecimals: 2,
    },
    {
      name: "BNB (Wrapped)",
      symbol: "WBNB",
      baseSymbol: "BNB",
      decimals: 18,
      address: getContractAddress(56, "WBNB"),
      imageUrl: BNB_icon,
      isShortable: false,
      isWrapped: true,
      isElp1: true,
      isElp1p: true,
      tokenDecimals: 2,
    },
    {
      name: "Binance USD",
      symbol: "BUSD",
      decimals: 18,
      address: getContractAddress(56, "BUSD"),
      imageUrl: BUSD_icon,
      isShortable: true,
      isStable: true,
      isElp1: true,
      isElp2: true,
      tokenDecimals: 2,
    },
    {
      name: "Tether",
      symbol: "USDT",
      decimals: 18,
      address: getContractAddress(56, "USDT"),
      imageUrl: USDT_icon,
      isShortable: true,
      isStable: true,
      isElp1: true,
      isElp1p: true,
      tokenDecimals: 2,
    },
    {
      name: "Bitcoin",
      symbol: "BTC",
      decimals: 18,
      address: getContractAddress(56, "BTC"),
      imageUrl: BTC_icon,
      isShortable: true,
      isLongable: true,
      isElp1: true,
      isElp1p: true,
      tokenDecimals: 2,
    },
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: getContractAddress(56, "ETH"),
      imageUrl: ETH_icon,
      isShortable: true,
      isLongable: true,
      isElp1: true,
      isElp1p: true,
      tokenDecimals: 2,
    },
    // {
    //   name: "Dogecoin",
    //   symbol: "DOGE",
    //   decimals: 18,
    //   address: getContractAddress(56, "DOGE"),
    //   imageUrl: DOGE_icon,
    //   isElp2: true,
    //   isLongable: true,
    //   isShortable: true,
    //   tokenDecimals: 4,
    // },
    {
      name: "Cardano",
      symbol: "ADA",
      decimals: 18,
      address: getContractAddress(56, "ADA"),
      imageUrl: ADA_icon,
      // isLongable: true,
      // isShortable: true,
      // isElp2: true,
      tokenDecimals: 4,
    },
    {
      name: "XRP",
      symbol: "XRP",
      decimals: 18,
      address: getContractAddress(56, "XRP"),
      imageUrl: XRP_icon,
      // isShortable: true,
      // isLongable: true,
      // isElp2: true,
      tokenDecimals: 4,
    },
    {
      name: "Chainlink",
      symbol: "LINK",
      decimals: 18,
      address: getContractAddress(56, "LINK"),
      imageUrl: LINK_icon,
      // isShortable: true,
      // isLongable: true,
      // isElp2: true,
      tokenDecimals: 4,
    },
    {
      name: "PancakeSwap",
      symbol: "CAKE",
      decimals: 18,
      address: getContractAddress(56, "CAKE"),
      imageUrl: CAKE_icon,
      // isShortable: true,
      // isLongable: true,
      // isElp2: true,
      tokenDecimals: 4,
    },
  ],
  421613: [
    {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
      isNative: true,
      address: ethers.constants.AddressZero,
      imageUrl: ETH_icon,
      isShortable: true,
      isLongable: true,
      isElp1: true,
      tokenDecimals: 2,
    },
    {
      name: "ETH (Wrapped)",
      symbol: "WETH",
      baseSymbol: "ETH",
      decimals: 18,
      address: getContractAddress(421613, "WETH"),
      imageUrl: ETH_icon,
      isShortable: false,
      isWrapped: true,
      isElp1: true,
      tokenDecimals: 2,
    },
    {
      name: "Bitcoin",
      symbol: "BTC",
      decimals: 8,
      address: getContractAddress(421613, "BTC"),
      imageUrl: BTC_icon,
      isShortable: true,
      isLongable: true,
      isElp1: true,
      tokenDecimals: 2,
    },
    {
      name: "USDC",
      symbol: "USDC",
      decimals: 6,
      address: getContractAddress(421613, "USDC"),
      imageUrl: USDC_icon,
      isShortable: true,
      isStable: true,
      isElp1: true,
      tokenDecimals: 2,
    },
    {
      name: "USDT",
      symbol: "USDT",
      decimals: 6,
      address: getContractAddress(421613, "USDT"),
      imageUrl: USDT_icon,
      isShortable: true,
      isStable: true,
      isElp1: true,
      tokenDecimals: 2,
    },
    {
      name: "DAI",
      symbol: "DAI",
      decimals: 18,
      address: getContractAddress(421613, "DAI"),
      imageUrl: DAI_icon,
      isShortable: true,
      isStable: true,
      isElp1: true,
      tokenDecimals: 2,
    },
  ],
  42161: [
    {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
      isNative: true,
      address: ethers.constants.AddressZero,
      imageUrl: ETH_icon,
      isShortable: true,
      isLongable: true,
      isElp1: true,
      tokenDecimals: 2,
    },
    {
      name: "ETH (Wrapped)",
      symbol: "WETH",
      baseSymbol: "ETH",
      decimals: 18,
      address: getContractAddress(42161, "WETH"),
      imageUrl: ETH_icon,
      isShortable: false,
      isWrapped: true,
      isElp1: true,
      tokenDecimals: 2,
    },
    {
      name: "Bitcoin",
      symbol: "BTC",
      decimals: 8,
      address: getContractAddress(42161, "BTC"),
      imageUrl: BTC_icon,
      isShortable: true,
      isLongable: true,
      isElp1: true,
      tokenDecimals: 2,
    },
    {
      name: "USDC",
      symbol: "USDC",
      decimals: 6,
      address: getContractAddress(42161, "USDC"),
      imageUrl: USDC_icon,
      isShortable: true,
      isStable: true,
      isElp1: true,
      tokenDecimals: 2,
    },
    {
      name: "USDT",
      symbol: "USDT",
      decimals: 6,
      address: getContractAddress(42161, "USDT"),
      imageUrl: USDT_icon,
      isShortable: true,
      isStable: true,
      isElp1: true,
      tokenDecimals: 2,
    },
    {
      name: "DAI",
      symbol: "DAI",
      decimals: 18,
      address: getContractAddress(42161, "DAI"),
      imageUrl: DAI_icon,
      isShortable: true,
      isStable: true,
      isElp1: true,
      tokenDecimals: 2,
    },
  ]
};

const ADDITIONAL_TOKENS = {
  97: [
    {
      name: "ELP",
      symbol: "ELP",
      address: getContractAddress(97, "ELP"),
      decimals: 18,
    },
  ],
  56: [
    {
      name: "ELP",
      symbol: "ELP",
      address: getContractAddress(56, "ELP"),
      decimals: 18,
    },
  ],
  421613: [
    {
      name: "ELP",
      symbol: "ELP",
      address: getContractAddress(421613, "ELP"),
      decimals: 18,
    },
  ],
  42161: [
    {
      name: "ELP",
      symbol: "ELP",
      address: getContractAddress(42161, "ELP"),
      decimals: 18,
    },
  ]
};

const CHAIN_IDS = [56, 97, 42161, 421613];
const TOKENS_MAP = {};
const TOKENS_BY_SYMBOL_MAP = {};

for (let j = 0; j < CHAIN_IDS.length; j++) {
  const chainId = CHAIN_IDS[j];
  TOKENS_MAP[chainId] = {};
  TOKENS_BY_SYMBOL_MAP[chainId] = {};
  let tokens = TOKENS[chainId];
  if (ADDITIONAL_TOKENS[chainId]) {
    tokens = tokens.concat(ADDITIONAL_TOKENS[chainId]);
  }

  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i];
    TOKENS_MAP[chainId][token.address] = token;
    TOKENS_BY_SYMBOL_MAP[chainId][token.symbol] = token;
  }
}

const WRAPPED_TOKENS_MAP = {};
const NATIVE_TOKENS_MAP = {};
for (const chainId of CHAIN_IDS) {
  for (const token of TOKENS[chainId]) {
    if (token.isWrapped) {
      WRAPPED_TOKENS_MAP[chainId] = token;
    } else if (token.isNative) {
      NATIVE_TOKENS_MAP[chainId] = token;
    }
  }
}

export function getWrappedToken(chainId) {
  return WRAPPED_TOKENS_MAP[chainId];
}

export function getNativeToken(chainId) {
  return NATIVE_TOKENS_MAP[chainId];
}

export function getTokens(chainId) {
  return TOKENS[chainId];
}

export function isValidToken(chainId, address) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  return address in TOKENS_MAP[chainId];
}

export function getToken(chainId, address) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  if (!TOKENS_MAP[chainId][address]) {
    throw new Error(`Incorrect address "${address}" for chainId ${chainId}`);
  }
  return TOKENS_MAP[chainId][address];
}

export function getTokenBySymbol(chainId, symbol) {
  const token = TOKENS_BY_SYMBOL_MAP[chainId][symbol];
  if (!token) {
    throw new Error(`Incorrect symbol "${symbol}" for chainId ${chainId}`);
  }
  return token;
}

export function getWhitelistedTokens(chainId) {
  return TOKENS[chainId].filter(token => token.symbol !== "USDX");
}

export function getWhitelistedTokens2(elpName, chainId) {
  const tokens = TOKENS[chainId]
  const token1 = tokens.filter(token => token.isElp1)
  const token2 = tokens.filter(token => token.isElp2)
  return elpName == "ELP-1" ? token1.filter(token => token.symbol !== "USDX") : token2.filter(token => token.symbol !== "USDX");
}

export function getTokenMeta(chainId, address) {
  let supportedTokens = TOKENS[chainId];
  let obj = supportedTokens.find(token => token.address.toLowerCase() === address.toLowerCase());

  return obj;
}
