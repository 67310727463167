import { Box, Dialog, Button, useMediaQuery } from "@material-ui/core";
import React, { useCallback, useState, useMemo } from "react";

import "../../views/dashboard/dialog.scss";
import Close from "../../assets/images/close.svg";
import "./ConfirmationBox.css";
import Tips from "../../assets/images/tips.svg";
import PendingIcon from "../../assets/images/loading/pending.svg";
import {
  USD_DECIMALS,
  PRECISION,
  BASIS_POINTS_DIVISOR,
  LIMIT,
  MIN_PROFIT_TIME,
  INCREASE,
  expandDecimals,
  getExchangeRate,
  getProfitPrice,
  getTimeRemaining,
  formatAmount,
  formatAmountFree,
  useLocalStorageSerializeKey,
  getExchangeRateDisplay,
  DEFAULT_SLIPPAGE_AMOUNT,
  DEFAULT_HIGHER_SLIPPAGE_AMOUNT,
  SLIPPAGE_BPS_KEY,
  formatDateTime,
  calculatePositionDelta,
  DECREASE,
  TRIGGER_PREFIX_ABOVE,
  TRIGGER_PREFIX_BELOW,
} from "../../helpers/Helpers";
import cx from "classnames";
import { getConstant } from "../../configs/getConstant";
import { getContractAddress } from "../../Addresses";

import { BsArrowRight } from "react-icons/bs";
import Tooltip from "../Tooltip/Tooltip";
import Checkbox from "../Checkbox/Checkbox";
import { cancelDecreaseOrder, handleCancelOrder } from "../../Api";
import { getNativeToken, getToken, getWrappedToken } from "../../configs/Tokens";
import { useWeb3Context } from "src/hooks";
import Warn from "../../assets/images/warn.svg";
import InputSelect from "src/components/InputSelect";
import { ReactComponent as Opnepositn } from "src/assets/images/opnepositn.svg";

const HIGH_SPREAD_THRESHOLD = expandDecimals(1, USD_DECIMALS).div(100); // 1%;

function getSpread(fromTokenInfo, toTokenInfo, isLong, nativeTokenAddress) {
  if (fromTokenInfo && fromTokenInfo.maxPrice && toTokenInfo && toTokenInfo.minPrice) {
    const fromDiff = fromTokenInfo.maxPrice.sub(fromTokenInfo.minPrice).div(2);
    const fromSpread = fromDiff.mul(PRECISION).div(fromTokenInfo.maxPrice.add(fromTokenInfo.minPrice).div(2));
    const toDiff = toTokenInfo.maxPrice.sub(toTokenInfo.minPrice).div(2);
    const toSpread = toDiff.mul(PRECISION).div(toTokenInfo.maxPrice.add(toTokenInfo.minPrice).div(2));

    let value = fromSpread.add(toSpread);

    const fromTokenAddress = fromTokenInfo.isNative ? nativeTokenAddress : fromTokenInfo.address;
    const toTokenAddress = toTokenInfo.isNative ? nativeTokenAddress : toTokenInfo.address;

    if (isLong && fromTokenAddress === toTokenAddress) {
      value = fromSpread;
    }

    return {
      value,
      isHigh: value.gt(HIGH_SPREAD_THRESHOLD),
    };
  }
}

export default function ConfirmationBox(props) {
  const isVerySmallScreen = useMediaQuery("(max-width: 680px)");
  const {
    elpName,
    fromToken,
    fromTokenInfo,
    toToken,
    toTokenInfo,
    isSwap,
    isLong,
    isMarketOrder,
    orderOption,
    isShort,
    toAmount,
    fromAmount,
    isHigherSlippageAllowed,
    setIsHigherSlippageAllowed,
    onConfirmationClick,
    setIsConfirming,
    shortCollateralAddress,
    hasExistingPosition,
    leverage,
    existingPosition,
    existingLiquidationPrice,
    displayLiquidationPrice,
    shortCollateralToken,
    isPendingConfirmation,
    triggerPriceUsd,
    triggerRatio,
    fees,
    feesUsd,
    isSubmitting,
    fromUsdMin,
    toUsdMax,
    nextAveragePrice,
    collateralTokenAddress,
    feeBps,
    orders,
    library,
    setPendingTxns,
    pendingTxns,
    minExecutionFee,
    minExecutionFeeUSD,
    minExecutionFeeErrorMessage,
  } = props;
  const handleClose = () => {
    setIsConfirming(false);
  };

  const { chainID } = useWeb3Context();

  const nativeTokenSymbol = getConstant(chainID, "nativeTokenSymbol");

  const [savedSlippageAmount] = useLocalStorageSerializeKey([chainID, SLIPPAGE_BPS_KEY], DEFAULT_SLIPPAGE_AMOUNT);
  const [isProfitWarningAccepted, setIsProfitWarningAccepted] = useState(false);
  const [isTriggerWarningAccepted, setIsTriggerWarningAccepted] = useState(false);
  const [isLimitOrdersVisible, setIsLimitOrdersVisible] = useState(false);

  const onCancelOrderClick = useCallback(
    order => {
      handleCancelOrder(elpName, chainID, library, order, { pendingTxns, setPendingTxns });
    },
    [library, pendingTxns, setPendingTxns, chainID],
  );

  let minOut;
  let fromTokenUsd;
  let toTokenUsd;

  let collateralAfterFees = fromUsdMin;
  if (feesUsd) {
    collateralAfterFees = fromUsdMin.sub(feesUsd);
  }

  if (isSwap) {
    minOut = toAmount.mul(BASIS_POINTS_DIVISOR - savedSlippageAmount).div(BASIS_POINTS_DIVISOR);

    fromTokenUsd = fromTokenInfo ? formatAmount(fromTokenInfo.minPrice, USD_DECIMALS, 2, true) : 0;
    toTokenUsd = toTokenInfo ? formatAmount(toTokenInfo.maxPrice, USD_DECIMALS, 2, true) : 0;
  }

  const getTitle = () => {
    if (!isMarketOrder) {
      return "CONFIRM LIMIT ORDER";
    }
    if (isSwap) {
      return "CONFIRM SWAP";
    }
    return isLong ? "CONFIRM " : "CONFIRM";
  };
  const title = getTitle();

  const existingOrder = useMemo(() => {
    const wrappedToken = getWrappedToken(chainID);
    for (const order of orders) {
      if (order.type !== INCREASE) continue;
      const sameToken =
        order.indexToken === wrappedToken.address ? toToken.isNative : order.indexToken === toToken.address;
      if (order.isLong === isLong && sameToken) {
        return order;
      }
    }
  }, [orders, chainID, isLong, toToken.address, toToken.isNative]);

  const existingOrders = useMemo(() => {
    const wrappedToken = getWrappedToken(chainID);
    return orders.filter(order => {
      if (order.type !== INCREASE) return false;
      const sameToken =
        order.indexToken === wrappedToken.address ? toToken.isNative : order.indexToken === toToken.address;
      return order.isLong === isLong && sameToken;
    });
  }, [orders, chainID, isLong, toToken.address, toToken.isNative]);

  const existingTriggerOrders = useMemo(() => {
    const wrappedToken = getWrappedToken(chainID);
    return orders.filter(order => {
      if (order.type !== DECREASE) return false;
      const sameToken =
        order.indexToken === wrappedToken.address ? toToken.isNative : order.indexToken === toToken.address;
      return order.isLong === isLong && sameToken;
    });
  }, [orders, chainID, isLong, toToken.address, toToken.isNative]);

  const decreaseOrdersThatWillBeExecuted = useMemo(() => {
    if (isSwap) return [];
    return existingTriggerOrders.filter(order => {
      if (order.triggerAboveThreshold) {
        return existingPosition?.markPrice.gte(order.triggerPrice);
      } else {
        return existingPosition?.markPrice.lte(order.triggerPrice);
      }
    });
  }, [existingPosition, existingTriggerOrders, isSwap]);

  const getError = () => {
    if (!isSwap && hasExistingPosition && !isMarketOrder) {
      const { delta, hasProfit } = calculatePositionDelta(triggerPriceUsd, existingPosition);
      if (hasProfit && delta.eq(0)) {
        return "Invalid price, see warning";
      }
    }
    if (isMarketOrder && hasPendingProfit && !isProfitWarningAccepted) {
      return "Forfeit profit not checked";
    }
    return false;
  };

  const getPrimaryText = () => {
    if (decreaseOrdersThatWillBeExecuted.length > 0 && !isTriggerWarningAccepted) {
      return `Accept confirmation of trigger orders`;
    }

    if (!isPendingConfirmation) {
      const error = getError();
      if (error) {
        return error;
      }

      if (isSwap) {
        return title;
      }
      const action = isMarketOrder ? (isLong ? "Long" : "Short") : "Create Order";

      if (
        isMarketOrder &&
        MIN_PROFIT_TIME > 0 &&
        hasExistingPosition &&
        existingPosition.delta.eq(0) &&
        existingPosition.pendingDelta.gt(0)
      ) {
        return isLong ? `Forfeit profit and ${action}` : `Forfeit profit and Short`;
      }

      return isMarketOrder && MIN_PROFIT_TIME > 0 ? `Accept minimum and ${action}` : action;
    }

    if (!isMarketOrder) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center">
          <div className="">Creating Order</div>
          <img src={PendingIcon} height={20} className="ml-12" />
        </Box>
      );
    }
    if (isSwap) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center">
          <div className="">Swapping</div>
          <img src={PendingIcon} height={20} className="ml-12" />
        </Box>
      );
    }
    if (isLong) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center">
          <div className="">Longing</div>
          <img src={PendingIcon} height={20} className="ml-12" />
        </Box>
      );
    }
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <div className="">Shorting</div>
        <img src={PendingIcon} height={20} className="ml-12" />
      </Box>
    );
  };
  const isPrimaryEnabled = () => {
    if (getError()) {
      return false;
    }
    if (decreaseOrdersThatWillBeExecuted.length > 0 && !isTriggerWarningAccepted) {
      return false;
    }
    return !isPendingConfirmation && !isSubmitting;
  };
  const nativeTokenAddress = getContractAddress(chainID, "NATIVE_TOKEN");
  const spread = getSpread(fromTokenInfo, toTokenInfo, isLong, nativeTokenAddress);
  // it's meaningless for limit/stop orders to show spread based on current prices
  const showSpread = isMarketOrder && !!spread;

  let allowedSlippage = savedSlippageAmount;
  if (isHigherSlippageAllowed) {
    allowedSlippage = DEFAULT_HIGHER_SLIPPAGE_AMOUNT;
  }

  const renderSpreadWarning = useCallback(() => {
    if (!isMarketOrder) {
      return null;
    }

    if (spread && spread.isHigh) {
      return (
        <div className="Confirmation-box-warning">
          The spread is {`>`} 1%, please ensure the trade details are acceptable before comfirming
        </div>
      );
    }
  }, [isMarketOrder, spread]);

  const renderFeeWarning = useCallback(() => {
    if (orderOption === LIMIT || !feeBps || feeBps <= 60) {
      return null;
    }

    if (isSwap) {
      return (
        <div className="Confirmation-box-warning">
          Fees are high to swap from {fromToken.symbol} to {toToken.symbol}.
        </div>
      );
    }

    if (!collateralTokenAddress) {
      return null;
    }

    const collateralToken = getToken(chainID, collateralTokenAddress);
    return (
      <div className="Confirmation-box-warning">
        Fees are high to swap from {fromToken.symbol} to {collateralToken.symbol}. <br />
        {collateralToken.symbol} is needed for collateral.
      </div>
    );
  }, [feeBps, isSwap, collateralTokenAddress, chainID, fromToken.symbol, toToken.symbol, orderOption]);

  const hasPendingProfit =
    MIN_PROFIT_TIME > 0 && existingPosition && existingPosition.delta.eq(0) && existingPosition.pendingDelta.gt(0);

  const renderMinProfitWarning = useCallback(() => {
    if (MIN_PROFIT_TIME === 0) {
      return null;
    }
    if (!isSwap) {
      if (hasExistingPosition) {
        const minProfitExpiration = existingPosition.lastIncreasedTime + MIN_PROFIT_TIME;
        if (isMarketOrder && existingPosition.delta.eq(0) && existingPosition.pendingDelta.gt(0)) {
          const profitPrice = getProfitPrice(existingPosition.markPrice, existingPosition);
          return (
            <div className="Confirmation-box-warning">
              Increasing this position at the current price will forfeit a&nbsp;
              <a
                // href="https://gmxio.gitbook.io/gmx/trading#minimum-price-change"
                href=""
                target="_blank"
                rel="noopener noreferrer"
              >
                pending profit
              </a>{" "}
              of {existingPosition.deltaStr}.<br />
              <br />
              Profit price: {existingPosition.isLong ? ">" : "<"} ${formatAmount(profitPrice, USD_DECIMALS, 2, true)}.
              This rule only applies for the next {getTimeRemaining(minProfitExpiration)}, until{" "}
              {formatDateTime(minProfitExpiration)}.
            </div>
          );
        }
        if (!isMarketOrder) {
          const { delta, hasProfit } = calculatePositionDelta(triggerPriceUsd, existingPosition);
          if (hasProfit && delta.eq(0)) {
            const profitPrice = getProfitPrice(existingPosition.markPrice, existingPosition);
            return (
              <div className="Confirmation-box-warning">
                This order will forfeit a&nbsp;
                <a
                  // href="https://gmxio.gitbook.io/gmx/trading#minimum-price-change"
                  href=""
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  profit
                </a>{" "}
                of {existingPosition.deltaStr}.<br />
                Profit price: {existingPosition.isLong ? ">" : "<"} ${formatAmount(profitPrice, USD_DECIMALS, 2, true)}.
                This rule only applies for the next {getTimeRemaining(minProfitExpiration)}, until{" "}
                {formatDateTime(minProfitExpiration)}.
              </div>
            );
          }
        }
      }

      return (
        <div className="Confirmation-box-warning">
          A minimum price change of&nbsp;
          <a
            // href="https://gmxio.gitbook.io/gmx/trading#minimum-price-change"
            href=""
            target="_blank"
            rel="noopener noreferrer"
          >
            1.5%
          </a>{" "}
          is required for a position to be in profit. This only applies for the first {MIN_PROFIT_TIME / 60 / 60} hours
          after increasing a position.
        </div>
      );
    }
  }, [isSwap, hasExistingPosition, existingPosition, isMarketOrder, triggerPriceUsd]);

  const renderExistingOrderWarning = useCallback(() => {
    if (isSwap || !existingOrder) {
      return;
    }
    const indexToken = getToken(chainID, existingOrder.indexToken);
    const sizeInToken = formatAmount(
      existingOrder.sizeDelta.mul(PRECISION).div(existingOrder.triggerPrice),
      USD_DECIMALS,
      4,
      true,
    );
    if (existingOrders?.length > 1) {
      return (
        <div>
          <div className="Confirmation-box-info">
            <span>
              You have multiple existing Increase {existingOrder.isLong ? "Long" : "Short"} {indexToken.symbol} limit
              orders{" "}
            </span>
            <span onClick={() => setIsLimitOrdersVisible(p => !p)} className="view-orders">
              ({isLimitOrdersVisible ? "hide" : "view"})
            </span>
          </div>
          {isLimitOrdersVisible && (
            <ul className="order-list">
              {existingOrders.map(order => {
                const { account, index, type, triggerAboveThreshold, triggerPrice } = order;
                const id = `${account}-${index}`;
                const triggerPricePrefix = triggerAboveThreshold ? TRIGGER_PREFIX_ABOVE : TRIGGER_PREFIX_BELOW;
                const indexToken = getToken(chainID, order.indexToken);

                return (
                  <li key={id} className="font-sm">
                    <p>
                      {type === INCREASE ? "Increase" : "Decrease"} {indexToken.symbol} {isLong ? "Long" : "Short"}{" "}
                      &nbsp;{triggerPricePrefix} ${formatAmount(triggerPrice, USD_DECIMALS, 2, true)}
                    </p>
                    <button onClick={() => onCancelOrderClick(order)}>Cancel</button>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      );
    }
    return (
      <div className="Confirmation-box-info">
        You have an active Limit Order to Increase {existingOrder.isLong ? "Long" : "Short"} {sizeInToken}{" "}
        {indexToken.symbol} (${formatAmount(existingOrder.sizeDelta, USD_DECIMALS, 2, true)}) at price $
        {formatAmount(existingOrder.triggerPrice, USD_DECIMALS, 2, true)}
      </div>
    );
  }, [existingOrder, isSwap, chainID, existingOrders, isLong, isLimitOrdersVisible, onCancelOrderClick]);

  const renderExistingTriggerErrors = useCallback(() => {
    if (isSwap || decreaseOrdersThatWillBeExecuted?.length < 1) {
      return;
    }
    const existingTriggerOrderLength = decreaseOrdersThatWillBeExecuted.length;
    const orderText = existingTriggerOrderLength > 1 ? "orders" : "order";
    return (
      <>
        <div className="Confirmation-box-warning">
          You have {existingTriggerOrderLength > 1 ? `${existingTriggerOrderLength}` : "an"} active trigger {orderText}{" "}
          that might execute immediately after you open this position. Please cancel the {orderText} or accept the
          confirmation to continue.
        </div>
        <ul className="order-list">
          {decreaseOrdersThatWillBeExecuted.map(order => {
            const { account, index, type, triggerAboveThreshold, triggerPrice } = order;
            const id = `${account}-${index}`;
            const triggerPricePrefix = triggerAboveThreshold ? TRIGGER_PREFIX_ABOVE : TRIGGER_PREFIX_BELOW;
            const indexToken = getToken(chainID, order.indexToken);
            return (
              <li key={id}>
                <p>
                  {type === INCREASE ? "Increase" : "Decrease"} {indexToken.symbol} {isLong ? "Long" : "Short"}
                  &nbsp;{triggerPricePrefix} ${formatAmount(triggerPrice, USD_DECIMALS, 2, true)}
                </p>
                <button
                  onClick={() =>
                    cancelDecreaseOrder(chainID, library, index, {
                      successMsg: "Order cancelled",
                      failMsg: "Cancel failed",
                      sentMsg: "Cancel submitted",
                      pendingTxns,
                      setPendingTxns,
                    })
                  }
                >
                  Cancel
                </button>
              </li>
            );
          })}
        </ul>
      </>
    );
  }, [decreaseOrdersThatWillBeExecuted, isSwap, chainID, library, pendingTxns, setPendingTxns, isLong]);

  const renderExistingTriggerWarning = useCallback(() => {
    if (
      isSwap ||
      existingTriggerOrders.length < 1 ||
      decreaseOrdersThatWillBeExecuted.length > 0 ||
      renderExistingOrderWarning()
    ) {
      return;
    }
    const existingTriggerOrderLength = existingTriggerOrders.length;
    return (
      <div className="Confirmation-box-info">
        You have {existingTriggerOrderLength > 1 ? `${existingTriggerOrderLength}` : "an"} active trigger{" "}
        {existingTriggerOrderLength > 1 ? "orders" : "order"} that could impact this position.
      </div>
    );
  }, [existingTriggerOrders, isSwap, decreaseOrdersThatWillBeExecuted, renderExistingOrderWarning]);

  const renderMain = useCallback(() => {
    if (isSwap) {
      return (
        <div className="">
          <Box p={"16px"} flex={"1"}>
            <Box>
              <InputSelect
                token={fromToken}
                leftLable={"Pay"}
                disabled={true}
                value={formatAmount(fromAmount, fromToken.decimals, 4, true)}
                usdText={`$${formatAmount(fromUsdMin, USD_DECIMALS, 2, true)}`}
              />
            </Box>
            <Box className="pay_swap">
              <Opnepositn className="pay_icon" />
            </Box>
            <Box>
              <InputSelect
                leftLable={"Receive"}
                token={toToken}
                disabled={true}
                value={formatAmount(toAmount, toToken.decimals, 2, true)}
                usdText={`$${formatAmount(toUsdMax, USD_DECIMALS, 2, true)}`}
              />
            </Box>
            <div className="pay_dtail">
              <div className="p_dtail">
                <div className="dtail_lt font-weight-6">Min. Receive</div>
                <div className="dtail_rt font-weight-6">
                  {formatAmount(minOut, toTokenInfo.decimals, 4, true)} {toTokenInfo.symbol}
                </div>
              </div>

              <div className="p_dtail">
                <div className="dtail_lt font-weight-6">Price</div>
                <div className="dtail_rt font-weight-6">
                  {getExchangeRateDisplay(getExchangeRate(fromTokenInfo, toTokenInfo), fromTokenInfo, toTokenInfo)}
                </div>
              </div>
              <div className="p_dtail">
                <div className="dtail_lt font-weight-6">Spread</div>
                <div className="dtail_rt font-weight-6">
                  {formatAmount(spread.value.mul(100), USD_DECIMALS, 2, true)}%
                </div>
              </div>
              <div className="p_dtail">
                <div className="dtail_lt font-weight-6">Fees</div>
                <div className="dtail_rt font-weight-6">
                  {formatAmount(feeBps, 2, 2, true)}% ({formatAmount(fees, fromTokenInfo.decimals, 4, true)}{" "}
                  {fromTokenInfo.symbol}: ${formatAmount(feesUsd, USD_DECIMALS, 2, true)})
                </div>
              </div>
              <div className="p_dtail">
                <div className="dtail_lt font-weight-6">{fromTokenInfo.symbol} Price</div>
                <div className="dtail_rt font-weight-6">{fromTokenUsd} USD</div>
              </div>
              <div className="p_dtail">
                <div className="dtail_lt font-weight-6">{toTokenInfo.symbol} Price</div>
                <div className="dtail_rt font-weight-6">{toTokenUsd} USD</div>
              </div>
            </div>
          </Box>
        </div>
      );
    }

    return (
      <div className="">
        <div className="open_position">
          <Box p={"16px"} flex={"1"}>
            <Box>
              <InputSelect
                token={fromToken}
                leftLable={"Pay"}
                disabled={true}
                value={formatAmount(fromAmount, fromToken.decimals, 4, true)}
                usdText={`$${formatAmount(fromUsdMin, USD_DECIMALS, 2, true)}`}
              />
            </Box>
            <Box className="pay_swap">
              <Opnepositn className="pay_icon" />
            </Box>
            <Box>
              <InputSelect
                leftLable={isLong ? "Long" : "Short"}
                isOpenPosition={true}
                token={toToken}
                disabled={true}
                rightLable={`${formatAmount(leverage, 4, 2)}x`}
                value={formatAmount(toAmount, toToken.decimals, 4, true)}
                usdText={`$${formatAmount(toUsdMax, USD_DECIMALS, 2, true)}`}
              />
            </Box>
            <div className="pay_dtail">
              <div className="p_dtail">
                <div className="dtail_lt font-weight-6">Profits In</div>
                <div className="dtail_rt font-weight-6">
                  {isShort && getToken(chainID, shortCollateralAddress).symbol}
                  {isLong && toTokenInfo.symbol}
                </div>
              </div>
              {!isMarketOrder && (
                <div className="p_dtail">
                  <div className="dtail_lt font-weight-6">Limit Price</div>
                  <div className="dtail_rt font-weight-6">${formatAmount(triggerPriceUsd, USD_DECIMALS, 2, true)}</div>
                </div>
              )}

              {/* 32 */}
              <div className="p_dtail">
                <div className="dtail_lt font-weight-6">Entry Price</div>
                <div className="dtail_rt font-weight-6">
                  {hasExistingPosition &&
                    toAmount &&
                    toAmount.gt(0) &&
                    ` $${formatAmount(existingPosition.averagePrice, USD_DECIMALS, 2, true)}`}

                  {nextAveragePrice && `$${formatAmount(nextAveragePrice, USD_DECIMALS, 2, true)}`}
                  {!nextAveragePrice && `-`}
                </div>
              </div>

              <div className="p_dtail">
                <div className="dtail_lt font-weight-6">Liq. Price</div>
                <div className="dtail_rt font-weight-6">
                  {hasExistingPosition &&
                    toAmount &&
                    toAmount.gt(0) &&
                    ` $${formatAmount(existingLiquidationPrice, USD_DECIMALS, 2, true)}`}
                  {toAmount &&
                    displayLiquidationPrice &&
                    `$${formatAmount(displayLiquidationPrice, USD_DECIMALS, 2, true)}`}
                  {!toAmount && displayLiquidationPrice && `-`}
                  {!displayLiquidationPrice && `-`}
                </div>
              </div>

              <div className="p_dtail">
                <div className="dtail_lt font-weight-6">Fees</div>
                <div className="dtail_rt font-weight-6">${formatAmount(feesUsd, USD_DECIMALS, 2, true)}</div>
              </div>
              <div className="p_dtail">
                <div className="dtail_lt font-weight-6">Spread</div>
                <div className="dtail_rt font-weight-6">
                  {formatAmount(spread.value.mul(100), USD_DECIMALS, 2, true)}%
                </div>
              </div>
            </div>
          </Box>
          <Box className="position_right">
            <Box className="rg_box">
              <div className="p_dtail">
                <div className="dtail_lt font-weight-6">Borrow Fee</div>
                <div className="dtail_rt font-weight-6">
                  {" "}
                  {isLong && toTokenInfo && formatAmount(toTokenInfo.fundingRate, 4, 4)}
                  {isShort && shortCollateralToken && formatAmount(shortCollateralToken.fundingRate, 4, 4)}
                  {((isLong && toTokenInfo && toTokenInfo.fundingRate) ||
                    (isShort && shortCollateralToken && shortCollateralToken.fundingRate)) &&
                    "% / 1h"}
                </div>
              </div>
              <div className="p_dtail">
                <div className="dtail_lt font-weight-6">Execution Fee</div>
                <div className="dtail_rt font-weight-6">
                  {formatAmountFree(minExecutionFee, 18, 5)} {nativeTokenSymbol}
                </div>
              </div>
              <div className="p_dtail">
                <div className="dtail_lt font-weight-6">Allowed Slippage</div>
                <div className="dtail_rt font-weight-6">{formatAmount(allowedSlippage, 2, 2)}%</div>
              </div>
            </Box>
            {renderExistingOrderWarning()}
          </Box>
        </div>
      </div>
    );
  }, [isSwap, fromAmount, fromToken, toToken, fromUsdMin, toUsdMax, isLong, toAmount]);

  const SWAP_ORDER_EXECUTION_GAS_FEE = getConstant(chainID, "SWAP_ORDER_EXECUTION_GAS_FEE");
  const INCREASE_ORDER_EXECUTION_GAS_FEE = getConstant(chainID, "INCREASE_ORDER_EXECUTION_GAS_FEE");
  const executionFee = isSwap ? SWAP_ORDER_EXECUTION_GAS_FEE : INCREASE_ORDER_EXECUTION_GAS_FEE;
  const renderExecutionFee = useCallback(() => {
    if (isMarketOrder) {
      return null;
    }
    return (
      <Box display="flex" justifyContent="space-between" alignItems="center" className="mt-10">
        <div className="color7">Execution Fee</div>
        <div className="">
          {formatAmount(executionFee, 18, 4)} {getNativeToken(chainID).symbol}
        </div>
      </Box>
    );
  }, [isMarketOrder, executionFee, chainID]);

  const renderAvailableLiquidity = useCallback(() => {
    let availableLiquidity;
    const riskThresholdBps = 5000;
    let isLiquidityRisk;
    const token = isSwap || isLong ? toTokenInfo : shortCollateralToken;

    if (!token || !token.poolAmount || !token.availableAmount) {
      return null;
    }

    if (isSwap) {
      const poolWithoutBuffer = token.poolAmount.sub(token.bufferAmount);
      availableLiquidity = token.availableAmount.gt(poolWithoutBuffer) ? poolWithoutBuffer : token.availableAmount;
      isLiquidityRisk = availableLiquidity.mul(riskThresholdBps).div(BASIS_POINTS_DIVISOR).lt(toAmount);
    } else {
      if (isShort) {
        availableLiquidity = token.availableAmount;

        let adjustedMaxGlobalShortSize;

        if (toTokenInfo.maxAvailableShort && toTokenInfo.maxAvailableShort.gt(0)) {
          adjustedMaxGlobalShortSize = toTokenInfo.maxAvailableShort
            .mul(expandDecimals(1, token.decimals))
            .div(expandDecimals(1, USD_DECIMALS));
        }

        if (adjustedMaxGlobalShortSize && adjustedMaxGlobalShortSize.lt(token.availableAmount)) {
          availableLiquidity = adjustedMaxGlobalShortSize;
        }

        const sizeTokens = toUsdMax.mul(expandDecimals(1, token.decimals)).div(token.minPrice);
        isLiquidityRisk = availableLiquidity.mul(riskThresholdBps).div(BASIS_POINTS_DIVISOR).lt(sizeTokens);
      } else {
        availableLiquidity = token.availableAmount;
        isLiquidityRisk = availableLiquidity.mul(riskThresholdBps).div(BASIS_POINTS_DIVISOR).lt(toAmount);
      }
    }

    if (!availableLiquidity) {
      return null;
    }

    return (
      <Box display="flex" justifyContent="space-between" alignItems="center" className="mt-10">
        <div className="color7">Available Liquidity</div>
        <div className="">
          <Tooltip
            position="right-bottom"
            handleClassName={isLiquidityRisk ? "negative" : null}
            handle={
              <>
                {formatAmount(availableLiquidity, token.decimals, token.isStable ? 0 : 2, true)} {token.symbol}
              </>
            }
            renderContent={() =>
              isLiquidityRisk
                ? "There may not be sufficient liquidity to execute your order when the price conditions are met"
                : "The order will only execute if the price conditions are met and there is sufficient liquidity"
            }
          />
        </div>
      </Box>
    );
  }, [toTokenInfo, shortCollateralToken, isShort, isLong, isSwap, toAmount, toUsdMax]);

  const renderMarginSection = useCallback(() => {
    return (
      <>
        <div>
          {renderMain()}
          {renderFeeWarning()}
          {renderMinProfitWarning()}
          {/* {renderExistingOrderWarning()} */}
          {renderExistingTriggerErrors()}
          {renderExistingTriggerWarning()}
          {/* {minExecutionFeeErrorMessage && <div className="Confirmation-box-warning">{minExecutionFeeErrorMessage}</div>} */}
          {/* <div className="summary font-13 font-weight-5">
            <div className="summaryInfo3">
              <div className="box1">
                {hasPendingProfit && isMarketOrder && (
                  <Box display="flex" alignItems="center" className="">
                    <div className="">
                      <Checkbox isChecked={isProfitWarningAccepted} setIsChecked={setIsProfitWarningAccepted} />
                    </div>
                    <div
                      className="color7 ml-4 pointer"
                      onClick={() => setIsProfitWarningAccepted(!isProfitWarningAccepted)}
                    >
                      Forfeit profit
                    </div>
                  </Box>
                )}
                {orderOption === LIMIT && renderAvailableLiquidity()}
                {isShort && (
                  <Box display="flex" justifyContent="space-between" alignItems="center" className="mt-10">
                    <div className="color7">Profits In</div>
                    <div className="">{getToken(chainID, shortCollateralAddress).symbol}</div>
                  </Box>
                )}
                {isLong && (
                  <Box display="flex" justifyContent="space-between" alignItems="center" className="mt-10">
                    <div className="color7">Profits In</div>
                    <div className="">{toTokenInfo.symbol}</div>
                  </Box>
                )}
                <Box display="flex" justifyContent="space-between" alignItems="center" className="mt-10">
                  <div className="color7">Leverage</div>
                  <div className="">
                    {hasExistingPosition && toAmount && toAmount.gt(0) && (
                      <div className="inline-block muted">
                        {formatAmount(existingPosition.leverage, 4, 2)}x
                        <BsArrowRight className="transition-arrow" />
                      </div>
                    )}
                    {toAmount && leverage && leverage.gt(0) && `${formatAmount(leverage, 4, 2)}x`}
                    {!toAmount && leverage && leverage.gt(0) && `-`}
                    {leverage && leverage.eq(0) && `-`}
                  </div>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" className="mt-10">
                  <div className="color7">Liq. Price</div>
                  <div className="">
                    {hasExistingPosition && toAmount && toAmount.gt(0) && (
                      <div className="inline-block muted">
                        ${formatAmount(existingLiquidationPrice, USD_DECIMALS, 2, true)}
                        <BsArrowRight className="transition-arrow" />
                      </div>
                    )}
                    {toAmount &&
                      displayLiquidationPrice &&
                      `$${formatAmount(displayLiquidationPrice, USD_DECIMALS, 2, true)}`}
                    {!toAmount && displayLiquidationPrice && `-`}
                    {!displayLiquidationPrice && `-`}
                  </div>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" className="mt-10">
                  <div className="color7">Fees</div>
                  <div className="">${formatAmount(feesUsd, USD_DECIMALS, 2, true)}</div>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" className="mt-10">
                  <div className="color7">Collateral</div>
                  <div className="">
                    <Tooltip
                      handle={`$${formatAmount(collateralAfterFees, USD_DECIMALS, 2, true)}`}
                      position="right-bottom"
                      renderContent={() => {
                        return (
                          <>
                            Your position's collateral after deducting fees.
                            <br />
                            <br />
                            Pay amount: ${formatAmount(fromUsdMin, USD_DECIMALS, 2, true)}
                            <br />
                            Fees: ${formatAmount(feesUsd, USD_DECIMALS, 2, true)}
                            <br />
                          </>
                        );
                      }}
                    />
                  </div>
                </Box>
              </div>
              <div className="box3">
                {showSpread && (
                  <Box display="flex" justifyContent="space-between" alignItems="center" className="mt-10">
                    <div className="color7">Spread</div>
                    <div className="">{formatAmount(spread.value.mul(100), USD_DECIMALS, 2, true)}%</div>
                  </Box>
                )}
                {isMarketOrder && (
                  <Box display="flex" justifyContent="space-between" alignItems="center" className="mt-10">
                    <div className="color7">Entry Price</div>
                    <div className="">
                      {hasExistingPosition && toAmount && toAmount.gt(0) && (
                        <div className="inline-block muted">
                          ${formatAmount(existingPosition.averagePrice, USD_DECIMALS, 2, true)}
                          <BsArrowRight className="transition-arrow" />
                        </div>
                      )}
                      {nextAveragePrice && `$${formatAmount(nextAveragePrice, USD_DECIMALS, 2, true)}`}
                      {!nextAveragePrice && `-`}
                    </div>
                  </Box>
                )}
                {!isMarketOrder && (
                  <Box display="flex" justifyContent="space-between" alignItems="center" className="mt-10">
                    <div className="color7">Limit Price</div>
                    <div className="">${formatAmount(triggerPriceUsd, USD_DECIMALS, 2, true)}</div>
                  </Box>
                )}
                <Box display="flex" justifyContent="space-between" alignItems="center" className="mt-10">
                  <div className="color7">Funding Fee</div>
                  <div className="">
                    {isLong && toTokenInfo && formatAmount(toTokenInfo.fundingRate, 4, 4)}
                    {isShort && shortCollateralToken && formatAmount(shortCollateralToken.fundingRate, 4, 4)}
                    {((isLong && toTokenInfo && toTokenInfo.fundingRate) ||
                      (isShort && shortCollateralToken && shortCollateralToken.fundingRate)) &&
                      "% / 1h"}
                  </div>
                </Box>
                {isMarketOrder && (
                  <Box display="flex" justifyContent="space-between" alignItems="center" className="mt-10">
                    <div className="color7">Execution Fee</div>
                    <div className="">
                      <Tooltip
                        handle={`${formatAmountFree(minExecutionFee, 18, 5)} ${nativeTokenSymbol}`}
                        position="right-top"
                        renderContent={() => {
                          return (
                            <>
                              Network fee: {formatAmountFree(minExecutionFee, 18, 5)} {nativeTokenSymbol} ($
                              {formatAmount(minExecutionFeeUSD, USD_DECIMALS, 2)})<br />
                              <br />
                              This is the network cost required to execute the postion.{" "}
                              <a
                                // href="https://gmxio.gitbook.io/gmx/trading#execution-fee"
                                href=""
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                More Info
                              </a>
                            </>
                          );
                        }}
                      />
                    </div>
                  </Box>
                )}
                <Box display="flex" justifyContent="space-between" alignItems="center" className="mt-10">
                  <div className="color7">Allowed Slippage</div>
                  <div className="">
                    <Tooltip
                      handle={`${formatAmount(allowedSlippage, 2, 2)}%`}
                      position="right-top"
                      renderContent={() => {
                        return (
                          <>
                            You can change this in the settings menu on the top right of the page.
                            <br />
                            <br />
                            Note that a low allowed slippage, e.g. less than 0.5%, may result in failed orders if prices
                            are volatile.
                          </>
                        );
                      }}
                    />
                  </div>
                </Box>
                {isMarketOrder && (
                  <Box display="flex" alignItems="center" className="mt-10">
                    <div className="">
                      <Checkbox isChecked={isHigherSlippageAllowed} setIsChecked={setIsHigherSlippageAllowed} />
                    </div>
                    <div
                      className="color7 ml-4 pointer"
                      onClick={() => setIsHigherSlippageAllowed(!isHigherSlippageAllowed)}
                    >
                      Allow up to 1% slippage
                    </div>
                  </Box>
                )}
                {decreaseOrdersThatWillBeExecuted.length > 0 && (
                  <Box display="flex" justifyContent="space-between" alignItems="center" className="mt-10">
                    <div className="">
                      <Checkbox isChecked={isTriggerWarningAccepted} setIsChecked={setIsTriggerWarningAccepted} />
                    </div>
                    <div
                      className="color7 ml-4 pointer"
                      onClick={() => setIsTriggerWarningAccepted(!isTriggerWarningAccepted)}
                    >
                      I am aware of the trigger orders
                    </div>
                  </Box>
                )}
                {renderExecutionFee()}
              </div>
            </div>
          </div> */}
        </div>
      </>
    );
  }, [
    renderMain,
    renderMinProfitWarning,
    shortCollateralAddress,
    isShort,
    isLong,
    toTokenInfo,
    nextAveragePrice,
    toAmount,
    hasExistingPosition,
    existingPosition,
    isMarketOrder,
    triggerPriceUsd,
    showSpread,
    spread,
    displayLiquidationPrice,
    existingLiquidationPrice,
    feesUsd,
    leverage,
    renderExecutionFee,
    shortCollateralToken,
    chainID,
    renderFeeWarning,
    hasPendingProfit,
    isProfitWarningAccepted,
    renderAvailableLiquidity,
    orderOption,
    fromUsdMin,
    collateralAfterFees,
    renderExistingOrderWarning,
    renderExistingTriggerWarning,
    renderExistingTriggerErrors,
    isHigherSlippageAllowed,
    setIsHigherSlippageAllowed,
    allowedSlippage,
    isTriggerWarningAccepted,
    decreaseOrdersThatWillBeExecuted,
    minExecutionFee,
    nativeTokenSymbol,
    minExecutionFeeUSD,
    minExecutionFeeErrorMessage,
  ]);

  const renderSwapSection = useCallback(() => {
    return (
      <>
        <div>
          {renderMain()}
          {renderFeeWarning()}
          {renderSpreadWarning()}
        </div>
      </>
    );
  }, [
    renderMain,
    renderSpreadWarning,
    fromTokenInfo,
    toTokenInfo,
    orderOption,
    showSpread,
    spread,
    feesUsd,
    feeBps,
    renderExecutionFee,
    fromTokenUsd,
    toTokenUsd,
    triggerRatio,
    fees,
    isMarketOrder,
    minOut,
    renderFeeWarning,
    renderAvailableLiquidity,
  ]);

  return (
    <Dialog open={true} onClose={handleClose}>
      <div className={cx("dialogBg w-812", { iswap: isSwap })}>
        <div className="dialogContent">
          <Box display="flex" justifyContent="space-between" alignItems="center" className="dialogHeader">
            <div className="">
              <div className="font-24 font-weight-b">{title}</div>
            </div>
            <img src={Close} onClick={handleClose} />
          </Box>
          {!isSwap && leverage / 10000 > 30 && (
            <div className="warnBox">
              <Box display="flex" alignItems="center" className="warnInfo">
                <img src={Warn} />
                <div className="ml-16 font-14 font-weight-6 color1">
                  Attention! You are attempting to leverage more than 30x. Be advised that you have an increased risk of
                  liquidations using high leverage.{" "}
                </div>
              </Box>
            </div>
          )}
          {isSwap && renderSwapSection()}
          {!isSwap && renderMarginSection()}
          <div className="Confirmation-box-row confirmationContainer" style={{ marginTop: "44px" }}>
            <button
              onClick={onConfirmationClick}
              className="App-cta Confirmation-box-button confirm_cat"
              disabled={!isPrimaryEnabled()}
              style={{ marginTop: 0 }}
            >
              {getPrimaryText()}
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
