import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { ethers } from "ethers";
import { getEdePrice } from "src/hooks/getPrice_ede";
import { abi as RewardRouterABI } from "../abis/RewardRouter.json";
import { abi as EdeStakeABI } from "../abis/edeStake.json";
import { abi as EdeABI } from "../abis/EDE.json";
import { abi as InfoHelperABI } from "../abis/InfoHelper.json";
import { addresses } from "../configs/constants";
import { getTokens } from "../configs/Tokens";
import { setAll } from "../helpers";
import { RootState } from "../store";
import { getTokensArr } from "./getTokens";
import { IBaseAddressAsyncThunk } from "./interfaces";
import { PLACEHOLDER_ACCOUNT } from "src/helpers/Helpers";
import axios from "axios";

const getFeeAmount = async () => {
  const dalyFeeUrl_arb = "";
  const dalyFeeUrl_bsc = "https://data.ede.finance/api/ede/dalyFee";
  const baseUrl = dalyFeeUrl_bsc;
  const res = await axios.get(baseUrl);
  return res.data;
};

export const loadAppDetails = createAsyncThunk(
  "app/loadAppDetails",
  async ({ networkID, provider, address }: IBaseAddressAsyncThunk) => {
    // console.log("loadAppDetails")

    const edePrice = await getEdePrice({ networkID, provider });

    const RewardRouterContract = new ethers.Contract(addresses[networkID].RewardRouter, RewardRouterABI, provider);
    const info = await RewardRouterContract.stakedELPnAmount();
    // console.log("info", info)
    const totalStaked_elp1 = Number(ethers.utils.formatUnits(info[1][0], 18));
    const stakingPool_elp1 = Number(ethers.utils.formatUnits(info[2][0], 18));
    let totalStaked_elp2 = 0;
    let stakingPool_elp2 = 0;
    let totalStaked_elp1p = 0;
    let stakingPool_elp1p = 0;
    if (networkID == 56) {
      //    || networkID == 97
      totalStaked_elp2 = Number(ethers.utils.formatUnits(info[1][1], 18));
      totalStaked_elp1p = Number(ethers.utils.formatUnits(info[1][2], 18));
      stakingPool_elp2 = Number(ethers.utils.formatUnits(info[2][1], 18));
      stakingPool_elp1p = Number(ethers.utils.formatUnits(info[2][2], 18));
    }

    const feeAmount = await getFeeAmount();

    let tokens = getTokens(networkID);
    const elp1TokenArr = await getTokensArr(
      "ELP-1",
      "Top 5 ETF",
      feeAmount.elp1,
      totalStaked_elp1,
      stakingPool_elp1,
      addresses[networkID].ELP,
      addresses[networkID].ElpManager,
      addresses[networkID].RewardTracker,
      tokens,
      networkID,
      provider,
      address || PLACEHOLDER_ACCOUNT,
    );
    let tokensArr = elp1TokenArr;
    // console.log("tokensArr1", tokensArr)
    if (networkID == 56) {
      // || networkID == 97
      const elp2TokenArr = await getTokensArr(
        "ELP-2",
        "Top 50 ETF",
        feeAmount.elp2,
        totalStaked_elp2,
        stakingPool_elp2,
        addresses[networkID].ELP_2,
        addresses[networkID].ElpManager_ELP_2,
        addresses[networkID].RewardTracker_ELP_2,
        tokens,
        networkID,
        provider,
        address || PLACEHOLDER_ACCOUNT,
      );
      const elp1TokenArr_pro = await getTokensArr(
        "ELP-1p",
        "Top 5 ETF",
        feeAmount.elp1,
        totalStaked_elp1p,
        stakingPool_elp1p,
        addresses[networkID].ELP_1p,
        addresses[networkID].ElpManager_ELP_1p,
        addresses[networkID].RewardTracker_ELP_1p,
        tokens,
        networkID,
        provider,
        address || PLACEHOLDER_ACCOUNT,
      );
      tokensArr = [...elp1TokenArr_pro, ...elp1TokenArr, ...elp2TokenArr];
    }
    // console.log("tokensArr", tokensArr)

    const collateralDetail = await RewardRouterContract.getEUSDCollateralDetail();
    const tokenAddressList = collateralDetail[0];
    const tokenPriceList = collateralDetail[1];
    const tokenPoolList = collateralDetail[2];

    let collateralList = [];
    for (let i = 0; i < tokenAddressList.length; i++) {
      const currTokenArr = tokens.filter((token: { address: any }) => tokenAddressList[i] == token.address);
      if (currTokenArr[0]) {
        const currToken = currTokenArr[0];
        const tokenAmount = ethers.utils.formatUnits(tokenPriceList[i], currTokenArr[0].decimals);
        const pool = ethers.utils.formatUnits(tokenPoolList[i], 30);
        const price = Number(pool) > 0 ? Number(pool) / Number(tokenAmount) : 0;
        const amount = Number(pool) > 0 ? Number(pool) / price : 0;
        collateralList.push({ amount, price, pool, ...currToken });
      }
    }

    const edeContract = new ethers.Contract(addresses[networkID].EDE, EdeABI, provider);
    const totalSupply_ede = Number(ethers.utils.formatUnits(await edeContract.totalSupply(), 18));
    const balance_ede_team = Number(
      ethers.utils.formatUnits(await edeContract.balanceOf(addresses[networkID].Team), 18),
    );
    const balance_LP_Farm = Number(
      ethers.utils.formatUnits(await edeContract.balanceOf(addresses[networkID].LP_Farm), 18),
    );
    const balance_ede_LP_Marketing = Number(
      ethers.utils.formatUnits(await edeContract.balanceOf(addresses[networkID].LP_Marketing), 18),
    );
    const balance_ede_Contracts_Reserve = Number(
      ethers.utils.formatUnits(await edeContract.balanceOf(addresses[networkID].Contracts_Reserve), 18),
    );
    const balance_ede_TokenVesting_EDE = Number(
      ethers.utils.formatUnits(await edeContract.balanceOf(addresses[networkID].TokenVesting_EDE), 18),
    );
    const balance_ede_TokenVesting_pEDE = Number(
      ethers.utils.formatUnits(await edeContract.balanceOf(addresses[networkID].TokenVesting_pEDE), 18),
    );
    const balance_ede_TokenVesting_pEDE2 = Number(
      ethers.utils.formatUnits(await edeContract.balanceOf(addresses[networkID].TokenVesting_pEDE2), 18),
    );
    const balance_ede_edeDistribution = Number(
      ethers.utils.formatUnits(await edeContract.balanceOf(addresses[networkID].edeDistribution), 18),
    );
    const balance_ede_EDE_BUSD = Number(
      ethers.utils.formatUnits(await edeContract.balanceOf(addresses[networkID].EDE_LP), 18),
    );
    const balance_ede_burn = Number(
      ethers.utils.formatUnits(await edeContract.balanceOf(addresses[networkID].ede_burn), 18),
    );
    const gedeContract = new ethers.Contract(addresses[networkID].veStaker, EdeStakeABI, provider);
    const totalStaked_ede = Number(ethers.utils.formatUnits(await gedeContract.totalEDESupply(), 18));

    const circulatingSupply =
      totalSupply_ede -
      balance_ede_TokenVesting_EDE -
      balance_LP_Farm -
      balance_ede_LP_Marketing -
      balance_ede_Contracts_Reserve -
      balance_ede_burn -
      balance_ede_TokenVesting_pEDE -
      balance_ede_TokenVesting_pEDE2 -
      balance_ede_edeDistribution; //- balance_ede_EDE_BUSD - totalStaked_ede - balance_ede_team
    const InfoHelperContract = new ethers.Contract(addresses[networkID].InfoHelper, InfoHelperABI, provider);
    const basicInfo = await InfoHelperContract.getBasicInfo(addresses[networkID].ESBT, address || PLACEHOLDER_ACCOUNT);
    const userInfoArr = basicInfo[2];
    const rank = userInfoArr[10];
    const totalSupply_gede = Number(ethers.utils.formatUnits(await gedeContract.totalSupplyAtNow(), 18));
    const circLocked = totalStaked_ede / circulatingSupply;
    const avgLockTime = ((totalSupply_gede / totalStaked_ede - 1) / 3) * 4;

    return {
      eusdPrice: 1,
      edePrice,
      tokensArr,
      collateralList,
      totalSupply_ede,
      totalStaked_ede,
      circulatingSupply,
      balance_ede_EDE_BUSD,
      rank,
      circLocked,
      avgLockTime,
    };
  },
);

const initialState = {
  loading: false,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadAppDetails.pending, state => {
        state.loading = true;
      })
      .addCase(loadAppDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadAppDetails.rejected, (state, { error }) => {
        state.loading = false;
      });
  },
});

const baseInfo = (state: RootState) => state.app;

export default appSlice.reducer;

export const { fetchAppSuccess } = appSlice.actions;

export const getAppState = createSelector(baseInfo, app => app);
