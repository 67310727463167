import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { ReactComponent as ArrowDropDownIcon } from "src/assets/images/downicon.svg";
import { More } from "../Menu";
const MyBox = styled(Box)`
  .title {
    display: flex;
    flex-direction: column;

    .list {
      display: flex;
      align-items: center;
      gap: 5px;
      padding-bottom: 32px;
    }
    .itmes {
      display: flex;
      flex-direction: column;
      font-size: 16px;
      color: rgba(238, 242, 249, 0.5);
      gap: 32px;
      padding-left: 16px;
      a {
        font-size: 16px;
        color: rgba(238, 242, 249, 0.5);
      }
    }
  }
`;

const H5Header = ({ toggleDrawer }: any) => {
  const old = window.localStorage.getItem("showH5NAV");
  const [showItem, setShowItem] = useState(Boolean(old));

  return (
    <MyBox className="menuItem">
      <div className="title">
        <div
          className="list"
          onClick={() => {
            window.localStorage.setItem("showH5NAV", "true");
            setShowItem(!showItem);
          }}
        >
          More <ArrowDropDownIcon />
        </div>
        <div className="itmes" onClick={() => toggleDrawer()}>
          {showItem &&
            More.map(item => {
              return (
                <div className="show_item">
                  <a href={item.link} target={item.target} rel="noopener noreferrer">
                    {item.name}
                  </a>
                </div>
              );
            })}
        </div>
      </div>
    </MyBox>
  );
};

export default H5Header;
