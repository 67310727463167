import { Contract } from "@ethersproject/contracts";
import { useMemo } from "react";
import { isAddress } from "src/utils";
import { useWeb3Context } from "./web3Context";
import { addresses as ADDRESSMAP } from "../configs/constants";
import { abi as PositionReaderABI } from "../abis/PositionReader.json";
import { abi as VaultReaderABI } from "../abis/VaultReader.json";
import { abi as VaultPriceFeedV21FastABi } from "../abis/VaultPriceFeedV21Fast.json";
import { abi as VaultUtilsABI } from "../abis/VaultUtils.json";
import { abi as ElpTransferHelperABI } from "../abis/ElpTransferHelper.json";
import {
  PositionReader,
  VaultReader,
  VaultUtils,
  VaultPriceFeedV21Fast,
  Token,
  RouterSign,
  Pair,
  ElpTransferHelper,
} from "src/abis/types";
import { abi as TokenABI } from "../abis/Token.json";
import { abi as PairABI } from "../abis/Pair.json";
import { abi as RouterSignAbi } from "../abis/RouterSign.json";
import { UniswapInterfaceMulticall } from "@uniswap/redux-multicall/dist/abi/types";
import UniswapInterfaceMulticallJson from "@uniswap/v3-periphery/artifacts/contracts/lens/UniswapInterfaceMulticall.sol/UniswapInterfaceMulticall.json";

import { AddressMap, AddressZero, MULTICALL_ADDRESS } from "../constants/address";
import { VaultUtilsblacklist } from "src/constants/misc";

const { abi: MulticallABI } = UniswapInterfaceMulticallJson;

export function useContract<T extends Contract = Contract>(
  _address: AddressMap | string | undefined,
  ABI: any[],
  withSigner = true,
): T | undefined {
  const { chainID: chainId, provider, address: account } = useWeb3Context();
  const signer = provider?.getSigner();

  const address = useMemo(() => {
    if (!chainId) return;
    return typeof _address == "string" ? _address : _address ? _address[chainId] : undefined;
  }, [_address, chainId]);

  return useMemo(() => {
    if (!provider || !account || !address) return;

    if (!isAddress(address) || address === AddressZero) {
      throw Error(`Invalid 'address' parameter '${address}'.`);
    }

    const contract = new Contract(address, ABI, signer ? signer : undefined);

    return contract;
  }, [ABI, account, address, provider, withSigner]) as T;
}

export const usePositionReaderContract = () => {
  const { chainID } = useWeb3Context();
  return useContract<PositionReader>(ADDRESSMAP[chainID]?.PositionReader, PositionReaderABI);
};

export const useVaultReaderContract = () => {
  const { chainID } = useWeb3Context();
  return useContract<VaultReader>(ADDRESSMAP[chainID]?.VaultReader, VaultReaderABI);
};

export function useInterfaceMulticall() {
  return useContract<UniswapInterfaceMulticall>(MULTICALL_ADDRESS, MulticallABI, false) as UniswapInterfaceMulticall;
}

export function useVaultUtilsContract(vaultUtilsAddress: string) {
  return useContract<VaultUtils>(vaultUtilsAddress, VaultUtilsABI, false);
}

export function useElpTransferHelperContract() {
  const { chainID } = useWeb3Context();
  return useContract<ElpTransferHelper>(ADDRESSMAP[chainID]?.ElpTransferHelper, ElpTransferHelperABI, false);
}

export function useVaultPriceFeedV2Fast() {
  const { chainID } = useWeb3Context();
  return useContract<VaultPriceFeedV21Fast>(ADDRESSMAP[chainID]?.VaultPriceFeedV2Fast, VaultPriceFeedV21FastABi, false);
}

export function useTokenContract(address?: string) {
  return useContract<Token>(address, TokenABI);
}

export function useRouterSignContract(address?: string) {
  return useContract<RouterSign>(address, RouterSignAbi);
}

export function usePairContract(address?: string) {
  return useContract<Pair>(address, PairABI);
}
