import { Box, Dialog, Button, useMediaQuery } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import React, { useState, useCallback, useEffect, useMemo } from "react";
import { ethers, logger } from "ethers";
import { BsArrowRight } from "react-icons/bs";

import "../../views/dashboard/dialog.scss";
import Close from "../../assets/images/close.svg";
import PendingIcon from "../../assets/images/loading/pending.svg";
import USD from "src/assets/images/usd.svg";
import {
  formatAmount,
  bigNumberify,
  DEFAULT_SLIPPAGE_AMOUNT,
  DEFAULT_HIGHER_SLIPPAGE_AMOUNT,
  USD_DECIMALS,
  DUST_USD,
  BASIS_POINTS_DIVISOR,
  USDX_ADDRESS,
  SLIPPAGE_BPS_KEY,
  TRIGGER_PREFIX_BELOW,
  TRIGGER_PREFIX_ABOVE,
  MIN_PROFIT_TIME,
  usePrevious,
  formatAmountFree,
  parseValue,
  expandDecimals,
  getTokenInfo,
  getLiquidationPrice,
  getLeverage,
  getMarginFee,
  PRECISION,
  MARKET,
  STOP,
  DECREASE,
  useLocalStorageSerializeKey,
  calculatePositionDelta,
  getDeltaStr,
  getProfitPrice,
  formatDateTime,
  getTimeRemaining,
  execInc,
  getLiquidationPrice2,
  getPositionSignature,
} from "../../helpers/Helpers";
import { getConstant } from "../../configs/getConstant";
import { createDecreaseOrder, callContract, useHasOutdatedUi } from "../../Api";
import { getContractAddress } from "../../Addresses";
import PositionRouter from "../../abis/PositionRouter.json";
import Checkbox from "../Checkbox/Checkbox";
import Tooltip from "../Tooltip/Tooltip";
import { getPositionRouterAddress } from "src/helpers/elpAddress";
import { useRouterSignContract } from "src/hooks/useContract";
import { addresses as ADDRESS } from "src/configs/constants.ts";
import { toastError } from "src/helpers/toastHelpers";
import Tab from "src/components/Tab/Tab";
import InputSelect from "../InputSelect";
import cx from "classnames";
const { AddressZero } = ethers.constants;
const ORDER_SIZE_DUST_USD = expandDecimals(1, USD_DECIMALS - 1); // $0.10

const sellToken = {
  symbol: "USD",
  imageUrl: USD,
};
const orderOptionLabels = {
  [MARKET]: "Market",
  [STOP]: "Trigger",
};

const CheckBox = styled(Box)`
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 14px 16px;
  display: flex;
  gap: 10px;
  font-size: 14px;
  cursor: pointer;
`;

function getTokenAmount(usdAmount, tokenAddress, max, infoTokens) {
  if (!usdAmount) {
    return;
  }
  if (tokenAddress === USDX_ADDRESS) {
    return usdAmount.mul(expandDecimals(1, 18)).div(PRECISION);
  }
  const info = getTokenInfo(infoTokens, tokenAddress);
  if (!info) {
    return;
  }
  if (max && !info.maxPrice) {
    return;
  }
  if (!max && !info.minPrice) {
    return;
  }

  return usdAmount.mul(expandDecimals(1, info.decimals)).div(max ? info.minPrice : info.maxPrice);
}

export default function PositionSeller(props) {
  const isVerySmallScreen = useMediaQuery("(max-width: 680px)");
  const {
    elpName,
    pendingPositions,
    setPendingPositions,
    positionsMap,
    positionKey,
    position,
    isVisible,
    setIsVisible,
    account,
    library,
    infoTokens,
    setPendingTxns,
    flagOrdersEnabled,
    savedIsPnlInLeverage,
    chainId,
    nativeTokenAddress,
    orders,
    isWaitingForPluginApproval,
    isPluginApproving,
    orderBookApproved,
    setOrdersToaOpen,
    positionRouterApproved,
    isWaitingForPositionRouterApproval,
    isPositionRouterApproving,
    approvePositionRouter,
    isHigherSlippageAllowed,
    setIsHigherSlippageAllowed,
    minExecutionFee,
    minExecutionFeeUSD,
    minExecutionFeeErrorMessage,
  } = props;

  const handleClose = () => {
    setIsVisible(false);
  };

  const [savedSlippageAmount] = useLocalStorageSerializeKey([chainId, SLIPPAGE_BPS_KEY], DEFAULT_SLIPPAGE_AMOUNT);
  const [keepLeverage, setKeepLeverage] = useLocalStorageSerializeKey([chainId, "Exchange-keep-leverage"], true);
  // const position = positionsMap && positionKey ? positionsMap[positionKey] : undefined;
  const [fromValue, setFromValue] = useState("");
  const [isProfitWarningAccepted, setIsProfitWarningAccepted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const prevIsVisible = usePrevious(isVisible);
  const positionRouterAddress = getPositionRouterAddress(chainId, elpName);
  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");

  const RouterSign_addr = ADDRESS[chainId]?.RouterSign_ELP;
  const RouterSignContract = useRouterSignContract(RouterSign_addr);

  let allowedSlippage = savedSlippageAmount;
  if (isHigherSlippageAllowed) {
    allowedSlippage = DEFAULT_HIGHER_SLIPPAGE_AMOUNT;
  }

  const orderOptions = [MARKET, STOP];
  let [orderOption, setOrderOption] = useState(MARKET);

  if (!flagOrdersEnabled) {
    orderOption = MARKET;
  }

  const needPositionRouterApproval = !positionRouterApproved && orderOption === MARKET;

  const onOrderOptionChange = option => {
    setOrderOption(option);
  };

  const onTriggerPriceChange = evt => {
    setTriggerPriceValue(evt || "");
  };
  const [triggerPriceValue, setTriggerPriceValue] = useState("");
  const triggerPriceUsd = orderOption === MARKET ? 0 : parseValue(triggerPriceValue, USD_DECIMALS);

  const [nextDelta, nextHasProfit = bigNumberify(0)] = useMemo(() => {
    if (!position) {
      return [bigNumberify(0), false];
    }

    if (orderOption !== STOP) {
      return [position.delta, position.hasProfit, position.deltaPercentage];
    }

    if (!triggerPriceUsd) {
      return [bigNumberify(0), false];
    }

    const { delta, hasProfit, deltaPercentage } = calculatePositionDelta(triggerPriceUsd, position);
    return [delta, hasProfit, deltaPercentage];
  }, [position, orderOption, triggerPriceUsd]);

  const existingOrders = useMemo(() => {
    if (orderOption === STOP && (!triggerPriceUsd || triggerPriceUsd.eq(0))) {
      return [];
    }
    if (!orders || !position) {
      return [];
    }

    const ret = [];
    for (const order of orders) {
      // only Stop orders can't be executed without corresponding opened position
      if (order.type !== DECREASE) continue;

      // if user creates Stop-Loss we need only Stop-Loss orders and vice versa
      if (orderOption === STOP) {
        const triggerAboveThreshold = triggerPriceUsd.gt(position.markPrice);
        if (triggerAboveThreshold !== order.triggerAboveThreshold) continue;
      }

      const sameToken =
        order.indexToken === nativeTokenAddress
          ? position.indexTokenInfo.isNative
          : order.indexToken === position.indexToken;
      if (order.isLong === position.isLong && sameToken) {
        ret.push(order);
      }
    }
    return ret;
  }, [position, orders, triggerPriceUsd, orderOption, nativeTokenAddress]);
  const existingOrder = existingOrders[0];

  const needOrderBookApproval = orderOption === STOP && !orderBookApproved;

  const { data: hasOutdatedUi } = useHasOutdatedUi();

  let collateralToken;
  let maxAmount;
  let maxAmountFormatted;
  let maxAmountFormattedFree;
  let fromAmount;

  let convertedAmount;
  let convertedAmountFormatted;

  let nextLeverage;
  let liquidationPrice;
  let nextLiquidationPrice;
  let isClosing;
  let sizeDelta;

  let nextCollateral;
  let collateralDelta = bigNumberify(0);
  let receiveAmount = bigNumberify(0);
  let convertedReceiveAmount = bigNumberify(0);
  let adjustedDelta = bigNumberify(0);

  let title;
  let fundingFee;
  let positionFee;
  let totalFees;
  if (position) {
    fundingFee = position.fundingFee;
    fromAmount = parseValue(fromValue, USD_DECIMALS);
    sizeDelta = fromAmount;

    title = `Close ${position.isLong ? "Long" : "Short"} ${position.indexTokenInfo.symbol}`;
    collateralToken = position.collateralTokenInfo;
    // liquidationPrice = getLiquidationPrice(position);
    liquidationPrice = getLiquidationPrice2(position);

    if (fromAmount) {
      isClosing = position.size.sub(fromAmount).lt(DUST_USD);
      positionFee = getMarginFee(fromAmount);
    }

    if (isClosing) {
      sizeDelta = position.size;
      receiveAmount = position.collateral;
    } else if (orderOption === STOP && sizeDelta && existingOrders.length > 0) {
      let residualSize = position.size;
      for (const order of existingOrders) {
        residualSize = residualSize.sub(order.sizeDelta);
      }
      if (residualSize.sub(sizeDelta).abs().lt(ORDER_SIZE_DUST_USD)) {
        sizeDelta = residualSize;
      }
    }

    if (sizeDelta) {
      adjustedDelta = nextDelta.mul(sizeDelta).div(position.size);
    }

    if (nextHasProfit) {
      receiveAmount = receiveAmount.add(adjustedDelta);
    } else {
      if (receiveAmount.gt(adjustedDelta)) {
        receiveAmount = receiveAmount.sub(adjustedDelta);
      } else {
        receiveAmount = bigNumberify(0);
      }
    }

    if (keepLeverage && sizeDelta && !isClosing) {
      collateralDelta = sizeDelta.mul(position.collateral).div(position.size);
      // if the position will be realising a loss then reduce collateralDelta by the realised loss
      if (!nextHasProfit) {
        const deductions = adjustedDelta.add(positionFee).add(fundingFee);
        if (collateralDelta.gt(deductions)) {
          collateralDelta = collateralDelta = collateralDelta.sub(deductions);
        } else {
          collateralDelta = bigNumberify(0);
        }
      }
    }

    receiveAmount = receiveAmount.add(collateralDelta);

    if (sizeDelta && positionFee && fundingFee && position.pendingPremiumFee && position.termTax) {
      totalFees = positionFee.add(fundingFee).add(position.pendingPremiumFee).add(position.termTax);
      if (receiveAmount.gt(totalFees)) {
        receiveAmount = receiveAmount.sub(totalFees);
      } else {
        receiveAmount = bigNumberify(0);
      }
    }
    convertedReceiveAmount = getTokenAmount(receiveAmount, collateralToken.address, false, infoTokens);

    if (isClosing) {
      nextCollateral = bigNumberify(0);
    } else {
      if (position.collateral) {
        nextCollateral = position.collateral;
        if (collateralDelta && collateralDelta.gt(0)) {
          nextCollateral = position.collateral.sub(collateralDelta);
        } else if (position.delta && position.delta.gt(0) && sizeDelta) {
          if (!position.hasProfit) {
            nextCollateral = nextCollateral.sub(adjustedDelta);
          }
        }
      }
    }

    maxAmount = position.size;
    maxAmountFormatted = formatAmount(maxAmount, USD_DECIMALS, 2, true);
    maxAmountFormattedFree = formatAmountFree(maxAmount, USD_DECIMALS, 2);
    if (fromAmount && collateralToken.maxPrice) {
      convertedAmount = fromAmount.mul(expandDecimals(1, collateralToken.decimals)).div(collateralToken.maxPrice);
      convertedAmountFormatted = formatAmount(convertedAmount, collateralToken.decimals, 4, true);
    }

    if (fromAmount) {
      if (!isClosing && !keepLeverage) {
        nextLeverage = getLeverage({
          size: position.size,
          sizeDelta,
          collateral: position.collateral,
          entryFundingRate: position.entryFundingRate,
          cumulativeFundingRate: position.cumulativeFundingRate,
          hasProfit: nextHasProfit,
          delta: nextDelta,
          includeDelta: savedIsPnlInLeverage,
        });
        // nextLiquidationPrice = getLiquidationPrice({
        //   isLong: position.isLong,
        //   size: position.size,
        //   sizeDelta,
        //   collateral: position.collateral,
        //   averagePrice: position.averagePrice,
        //   entryFundingRate: position.entryFundingRate,
        //   cumulativeFundingRate: position.cumulativeFundingRate,
        //   delta: nextDelta,
        //   hasProfit: nextHasProfit,
        //   includeDelta: true,
        // });
        nextLiquidationPrice = getLiquidationPrice2(position, collateralDelta);
      }
    }
  }

  const [deltaStr, deltaPercentageStr] = useMemo(() => {
    if (!position || !position.markPrice) {
      return ["-", "-"];
    }
    if (orderOption !== STOP) {
      const { pendingDelta, pendingDeltaPercentage, hasProfit } = calculatePositionDelta(
        position.markPrice,
        position,
        fromAmount,
      );
      const { deltaStr, deltaPercentageStr } = getDeltaStr({
        delta: pendingDelta,
        deltaPercentage: pendingDeltaPercentage,
        hasProfit,
      });
      return [deltaStr, deltaPercentageStr];
    }
    if (!triggerPriceUsd || triggerPriceUsd.eq(0)) {
      return ["-", "-"];
    }

    const { pendingDelta, pendingDeltaPercentage, hasProfit } = calculatePositionDelta(
      triggerPriceUsd,
      position,
      fromAmount,
    );

    const { deltaStr, deltaPercentageStr } = getDeltaStr({
      delta: pendingDelta,
      deltaPercentage: pendingDeltaPercentage,
      hasProfit,
    });
    return [deltaStr, deltaPercentageStr];
  }, [position, triggerPriceUsd, orderOption, fromAmount]);

  const getError = () => {
    if (hasOutdatedUi) {
      return "Page outdated, please refresh";
    }
    if (!fromAmount) {
      return "Enter an amount";
    }
    if (nextLeverage && nextLeverage.eq(0)) {
      return "Enter an amount";
    }
    if (orderOption === STOP) {
      if (!triggerPriceUsd || triggerPriceUsd.eq(0)) {
        return "Enter Price";
      }
      if (position.isLong && triggerPriceUsd.lte(liquidationPrice)) {
        return "Price below Liq. Price";
      }
      if (!position.isLong && triggerPriceUsd.gte(liquidationPrice)) {
        return "Price above Liq. Price";
      }

      if (profitPrice && nextDelta.eq(0) && nextHasProfit) {
        return "Invalid price, see warning";
      }
    }

    if (!isClosing && position && position.size && fromAmount) {
      if (position.size.sub(fromAmount).lt(expandDecimals(10, USD_DECIMALS))) {
        return "Leftover position below 10 USD";
      }
      if (nextCollateral && nextCollateral.lt(expandDecimals(5, USD_DECIMALS))) {
        return "Leftover collateral below 5 USD";
      }
    }

    if (position && position.size && position.size.lt(fromAmount)) {
      return "Max close amount exceeded";
    }

    if (nextLeverage && nextLeverage.lt(1.1 * BASIS_POINTS_DIVISOR)) {
      return "Min leverage: 1.1x";
    }

    if (nextLeverage && nextLeverage.gt(30.5 * BASIS_POINTS_DIVISOR)) {
      return "Max leverage: 30.5x";
    }

    if (hasPendingProfit && orderOption !== STOP && !isProfitWarningAccepted) {
      return "Forfeit profit not checked";
    }
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isSubmitting) {
      return false;
    }
    if (needOrderBookApproval && isWaitingForPluginApproval) {
      return false;
    }
    if (isPluginApproving) {
      return false;
    }
    if (needPositionRouterApproval && isWaitingForPositionRouterApproval) {
      return false;
    }
    if (isPositionRouterApproving) {
      return false;
    }

    return true;
  };

  const hasPendingProfit = MIN_PROFIT_TIME > 0 && position.delta.eq(0) && position.pendingDelta.gt(0);

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }

    if (orderOption === STOP) {
      if (isSubmitting)
        return (
          <Box display="flex" justifyContent="center" alignItems="center">
            <div className="">Creating Order</div>
            <img src={PendingIcon} height={20} className="ml-12" />
          </Box>
        );

      if (needOrderBookApproval && isWaitingForPluginApproval) {
        return (
          <Box display="flex" justifyContent="center" alignItems="center">
            <div className="">Enabling Orders</div>
            <img src={PendingIcon} height={20} className="ml-12" />
          </Box>
        );
      }
      if (isPluginApproving) {
        return (
          <Box display="flex" justifyContent="center" alignItems="center">
            <div className="">Enabling Orders</div>
            <img src={PendingIcon} height={20} className="ml-12" />
          </Box>
        );
      }
      if (needOrderBookApproval) {
        return "Enable Orders";
      }

      return "Create Order";
    }

    if (needPositionRouterApproval && isWaitingForPositionRouterApproval) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center">
          <div className="">Enabling Leverage</div>
          <img src={PendingIcon} height={20} className="ml-12" />
        </Box>
      );
    }

    if (isPositionRouterApproving) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center">
          <div className="">Enabling Leverage</div>
          <img src={PendingIcon} height={20} className="ml-12" />
        </Box>
      );
    }

    if (needPositionRouterApproval) {
      return "Enable Leverage";
    }

    if (hasPendingProfit) {
      return "Close without profit";
    }
    return isSubmitting ? (
      <Box display="flex" justifyContent="center" alignItems="center">
        <div className="">Closing</div>
        <img src={PendingIcon} height={20} className="ml-12" />
      </Box>
    ) : (
      "Close"
    );
  };

  const resetForm = () => {
    setFromValue("");
    setIsProfitWarningAccepted(false);
  };

  useEffect(() => {
    if (prevIsVisible !== isVisible) {
      resetForm();
    }
  }, [prevIsVisible, isVisible]);

  const onClickPrimary = async () => {
    if (needOrderBookApproval) {
      setOrdersToaOpen(true);
      return;
    }

    if (needPositionRouterApproval) {
      approvePositionRouter({
        sentMsg: "Enable leverage sent.",
        failMsg: "Enable leverage failed.",
      });
      return;
    }

    setIsSubmitting(true);
    console.log("position.collateralTokenInfo", position.collateralTokenInfo);
    const collateralTokenAddress = position.collateralTokenInfo.isNative
      ? nativeTokenAddress
      : position.collateralToken;
    const indexTokenAddress = position.indexTokenInfo.isNative ? nativeTokenAddress : position.indexToken;

    if (orderOption === STOP) {
      const triggerAboveThreshold = triggerPriceUsd.gt(position.markPrice);

      createDecreaseOrder(
        elpName,
        chainId,
        library,
        indexTokenAddress,
        sizeDelta,
        collateralTokenAddress,
        collateralDelta,
        position.isLong,
        triggerPriceUsd,
        triggerAboveThreshold,
        {
          sentMsg: "Order submitted!",
          successMsg: "Order created!",
          failMsg: "Order creation failed.",
          setPendingTxns,
        },
      )
        .then(() => {
          setFromValue("");
          setIsVisible(false);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
      return;
    }

    const tokenAddress0 = collateralTokenAddress === AddressZero ? nativeTokenAddress : collateralTokenAddress;
    const priceBasisPoints = position.isLong
      ? BASIS_POINTS_DIVISOR - allowedSlippage
      : BASIS_POINTS_DIVISOR + allowedSlippage;
    const refPrice = position.isLong ? position.indexTokenInfo.minPrice : position.indexTokenInfo.maxPrice;
    let priceLimit = refPrice.mul(priceBasisPoints).div(BASIS_POINTS_DIVISOR);
    const minProfitExpiration = position.lastIncreasedTime + MIN_PROFIT_TIME;
    const minProfitTimeExpired = parseInt(Date.now() / 1000) > minProfitExpiration;
    if (nextHasProfit && !minProfitTimeExpired && !isProfitWarningAccepted) {
      if ((position.isLong && priceLimit.lt(profitPrice)) || (!position.isLong && priceLimit.gt(profitPrice))) {
        priceLimit = profitPrice;
      }
    }

    const withdrawETH = collateralTokenAddress === AddressZero || collateralTokenAddress === nativeTokenAddress;

    let signeData;
    try {
      const data = await getPositionSignature(chainId);
      signeData = data.data;
    } catch (error) {
      console.log(error);
    }
    if (
      signeData.code != 200 ||
      !signeData.data._priceBits ||
      !signeData.data._priceTimestamp ||
      !signeData.data._updater ||
      !signeData.data._updaterSignedMsg
    ) {
      toastError("System error!");
      setIsSubmitting(false);
      return;
    }

    const params = [
      [tokenAddress0], // _path
      indexTokenAddress, // _indexToken
      collateralDelta, // _collateralDelta
      sizeDelta, // _sizeDelta
      position.isLong, // _isLong
      account, // _receiver
      priceLimit, // _acceptablePrice
      0, // _minOut
      minExecutionFee, // _executionFee
      withdrawETH, // _withdrawETH
    ];

    const params2 = [
      tokenAddress0, // _collateralToken
      indexTokenAddress, // _indexToken
      collateralDelta, // _collateralDelta
      sizeDelta, // _sizeDelta
      position.isLong, // _isLong
      account, // _receiver
      priceLimit, // _acceptablePrice
      signeData?.data?._priceBits, //_priceBits
      signeData?.data?._priceTimestamp, //_priceTimestamp
      signeData?.data?._updater, //_updater
      signeData?.data?._updaterSignedMsg, //_updaterSignedMsg
    ];

    const successMsg = `Requested decrease of ${position.indexTokenInfo.symbol} ${
      position.isLong ? "Long" : "Short"
    } by ${formatAmount(sizeDelta, USD_DECIMALS, 2)} USD.`;

    // console.log(params, "params");

    // const contract = new ethers.Contract(positionRouterAddress, PositionRouter.abi, library.getSigner());
    debugger;
    let method = "createDecreasePosition";
    let allparams = params2;
    if (withdrawETH) {
      method = "decreasePositionETH";
    } else {
      method = "decreasePosition";
    }
    let contracts = RouterSignContract;
    callContract(chainId, contracts, method, allparams, {
      value: minExecutionFee,
      sentMsg: "Close submitted!",
      successMsg,
      failMsg: "Close failed.",
      setPendingTxns,
    })
      .then(async res => {
        setFromValue("");
        setIsVisible(false);

        let nextSize = position.size.sub(sizeDelta);
        const cData = {
          [position.key]: {
            updatedAt: Date.now(),
            pendingChanges: {
              size: nextSize,
            },
          },
        };

        setPendingPositions({});
      })
      .catch(e => {
        console.log("error", e);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const renderExistingOrderWarning = useCallback(() => {
    if (!existingOrder) {
      return;
    }
    const indexToken = getTokenInfo(infoTokens, existingOrder.indexToken);
    const sizeInToken = formatAmount(
      existingOrder.sizeDelta.mul(PRECISION).div(existingOrder.triggerPrice),
      USD_DECIMALS,
      4,
      true,
    );
    const prefix = existingOrder.triggerAboveThreshold ? TRIGGER_PREFIX_ABOVE : TRIGGER_PREFIX_BELOW;
    return (
      <div className="Confirmation-box-warning">
        You have an active order to decrease {existingOrder.isLong ? "Long" : "Short"} {sizeInToken} {indexToken.symbol}{" "}
        ($
        {formatAmount(existingOrder.sizeDelta, USD_DECIMALS, 2, true)}) at {prefix}{" "}
        {formatAmount(existingOrder.triggerPrice, USD_DECIMALS, 2, true)}
      </div>
    );
  }, [existingOrder, infoTokens]);

  function renderMinProfitWarning() {
    if (MIN_PROFIT_TIME === 0) {
      return null;
    }

    if (profitPrice && nextDelta.eq(0) && nextHasProfit) {
      const minProfitExpiration = position.lastIncreasedTime + MIN_PROFIT_TIME;

      if (orderOption === MARKET) {
        return (
          <div className="Confirmation-box-warning">
            Reducing the position at the current price will forfeit a&nbsp;
            <a
              // href="https://gmxio.gitbook.io/gmx/trading#minimum-price-change"
              href=""
              target="_blank"
              rel="noopener noreferrer"
            >
              pending profit
            </a>{" "}
            of {deltaStr}. <br />
            <br />
            Profit price: {position.isLong ? ">" : "<"} ${formatAmount(profitPrice, USD_DECIMALS, 2, true)}. This rule
            applies for the next {getTimeRemaining(minProfitExpiration)}, until {formatDateTime(minProfitExpiration)}.
          </div>
        );
      }
      return (
        <div className="Confirmation-box-warning">
          This order will forfeit a&nbsp;
          <a
            // href="https://gmxio.gitbook.io/gmx/trading#minimum-price-change"
            href=""
            target="_blank"
            rel="noopener noreferrer"
          >
            profit
          </a>{" "}
          of {deltaStr}. <br />
          Profit price: {position.isLong ? ">" : "<"} ${formatAmount(profitPrice, USD_DECIMALS, 2, true)}. This rule
          applies for the next {getTimeRemaining(minProfitExpiration)}, until {formatDateTime(minProfitExpiration)}.
        </div>
      );
    }
  }

  const DECREASE_ORDER_EXECUTION_GAS_FEE = getConstant(chainId, "DECREASE_ORDER_EXECUTION_GAS_FEE");
  function renderExecutionFee() {
    if (orderOption !== STOP) {
      return null;
    }
    return (
      <Box display="flex" justifyContent="space-between" alignItems="center" className="mt-10">
        <div className="color7">Execution Fee</div>
        <div className="">
          {formatAmount(DECREASE_ORDER_EXECUTION_GAS_FEE, 18, 4)} {nativeTokenSymbol}
        </div>
      </Box>
    );
  }

  const profitPrice = getProfitPrice(orderOption === MARKET ? position.markPrice : triggerPriceUsd, position);

  let triggerPricePrefix;
  if (triggerPriceUsd) {
    triggerPricePrefix = triggerPriceUsd.gt(position.markPrice) ? TRIGGER_PREFIX_ABOVE : TRIGGER_PREFIX_BELOW;
  }

  const shouldShowExistingOrderWarning = false;
  return (
    <Dialog open={isVisible} onClose={handleClose}>
      <div className="dialogBg w-812">
        <div className="dialogContent">
          <Box display="flex" justifyContent="space-between" alignItems="center" className="dialogHeader">
            <div className="">
              <div className="font-24 font-weight-b">{title}</div>
            </div>
            <img src={Close} onClick={handleClose} />
          </Box>
          <div className="dialogInfo sellers">
            <Box display={"flex"} className="h5sellers" justifyContent="flex-start" flex={1} pb={"32px"}>
              <Box flex={"1"}>
                {flagOrdersEnabled && (
                  <Box display="flex" className="" width={"100%"} mb={"16px"}>
                    <Tab
                      options={orderOptions}
                      optionLabels={orderOptionLabels}
                      className="Exchange-swap-order-type-tabs "
                      type="inline"
                      option={orderOption}
                      onChange={e => {
                        onOrderOptionChange(e);
                      }}
                    />
                    {/* <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  className={`tabItem color7 ${orderOption == MARKET && "tabItemActive"}`}
                  onClick={() => onOrderOptionChange("Market")}
                >
                  Market
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  className={`tabItem color7 ml-20 ${orderOption == STOP && "tabItemActive"}`}
                  onClick={() => onOrderOptionChange("Stop")}
                >
                  Trigger
                </Box> */}
                  </Box>
                )}
                {orderOption === STOP && (
                  <InputSelect
                    className="mt-16"
                    leftLable={`Price`}
                    value={triggerPriceValue}
                    totalBalance={maxAmountFormatted}
                    onUserInput={onTriggerPriceChange}
                    token={sellToken}
                    usdText={`Mark: $${formatAmount(position.markPrice, USD_DECIMALS, 2, true)}`}
                    onUsdClick={() => {
                      setTriggerPriceValue(formatAmountFree(position.markPrice, USD_DECIMALS, 2));
                    }}
                  />
                )}
                <InputSelect
                  className={"mt-16"}
                  leftLable={`Close`}
                  balance={`Open Interest : ${maxAmountFormatted} `}
                  showMax={true}
                  value={fromValue}
                  totalBalance={maxAmountFormattedFree}
                  onUserInput={setFromValue}
                  token={sellToken}
                  usdText={` ${convertedAmountFormatted || 0} ${position.collateralTokenInfo.symbol}`}
                />

                {hasPendingProfit && orderOption !== STOP && (
                  <Box display="flex" justifyContent="space-between" alignItems="center" className="mt-10">
                    <div
                      className="color7 pointer"
                      onClick={() => setIsProfitWarningAccepted(!isProfitWarningAccepted)}
                    >
                      Forfeit profit
                    </div>
                    <div className="">
                      <Checkbox isChecked={isProfitWarningAccepted} setIsChecked={setIsProfitWarningAccepted} />
                    </div>
                  </Box>
                )}

                <Box display={"flex"} pt={"16px"} gridGap={"8px"} flexDirection={"column"}>
                  <CheckBox className="check_box font-weight-6">
                    <Checkbox isChecked={keepLeverage} setIsChecked={setKeepLeverage}></Checkbox>
                    <div
                      onClick={() => setKeepLeverage(!keepLeverage)}
                      className={cx("check_size", { isChecked: isProfitWarningAccepted })}
                    >
                      Keep leverage at {formatAmount(position?.leverage, 4, 2)}x
                    </div>
                  </CheckBox>
                  {orderOption === MARKET && (
                    <CheckBox className="check_box font-weight-6">
                      <Checkbox
                        isChecked={isHigherSlippageAllowed}
                        setIsChecked={setIsHigherSlippageAllowed}
                      ></Checkbox>
                      <div
                        onClick={() => setIsHigherSlippageAllowed(!isHigherSlippageAllowed)}
                        className={cx("check_size", { isChecked: isProfitWarningAccepted })}
                      >
                        Allow up to 1% slippage
                      </div>
                    </CheckBox>
                  )}
                </Box>

                <Box
                  className="rg_box"
                  display={"flex"}
                  flexDirection="column"
                  gridGap={"12px"}
                  pt={"16px"}
                  pl={"18px"}
                  pr={"14px"}
                >
                  {orderOption === STOP && (
                    <div className="p_dtail">
                      <div className="dtail_lt font-weight-6">Trigger Price</div>
                      <div className="dtail_rt font-weight-6">
                        {!triggerPriceUsd && "-"}
                        {triggerPriceUsd &&
                          `${triggerPricePrefix} ${formatAmount(triggerPriceUsd, USD_DECIMALS, 2, true)}`}
                      </div>
                    </div>
                  )}
                  <div className="p_dtail">
                    <div className="dtail_lt font-weight-6">Mark Price</div>
                    <div className="dtail_rt font-weight-6">
                      ${formatAmount(position.markPrice, USD_DECIMALS, 2, true)}
                    </div>
                  </div>
                  {orderOption === MARKET && (
                    <div className="p_dtail">
                      <div className="dtail_lt font-weight-6">Allowed Slippage</div>
                      <div className="dtail_rt font-weight-6">
                        {" "}
                        <Tooltip
                          handle={`${formatAmount(allowedSlippage, 2, 2)}%`}
                          position="right-bottom"
                          renderContent={() => {
                            return (
                              <>
                                You can change this in the settings menu on the top right of the page.
                                <br />
                                <br />
                                Note that a low allowed slippage, e.g. less than 0.5%, may result in failed orders if
                                prices are volatile.
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <div className="p_dtail">
                    <div className="dtail_lt font-weight-6">Receive</div>
                    <div className="dtail_rt font-weight-6">
                      {formatAmount(convertedReceiveAmount, position.collateralTokenInfo.decimals, 4, true)}{" "}
                      {position.collateralTokenInfo.symbol} ($
                      {formatAmount(receiveAmount, USD_DECIMALS, 2, true)})
                    </div>
                  </div>
                </Box>
              </Box>

              <Box className="sellrg_box">
                <div className="boders_sell">
                  {orderOption === STOP && (
                    <div className="p_dtail Triggers">
                      <div className="dtail_lt font-weight-6">Trigger Price</div>
                      <div className="dtail_rt font-weight-6">
                        {!triggerPriceUsd && "-"}
                        {triggerPriceUsd &&
                          `${triggerPricePrefix} ${formatAmount(triggerPriceUsd, USD_DECIMALS, 2, true)}`}
                      </div>
                    </div>
                  )}
                  <div className="p_list">
                    <div className="p_dtail">
                      <div className="dtail_lt font-weight-6">Entry Price</div>
                      <div className="dtail_rt font-weight-6">
                        ${formatAmount(position.averagePrice, USD_DECIMALS, 2, true)}
                      </div>
                    </div>
                    <div className="p_dtail">
                      <div className="dtail_lt font-weight-6">Liq. Price</div>
                      <div className="dtail_rt font-weight-6">
                        <div className="">
                          {isClosing && orderOption !== STOP && "-"}
                          {(!isClosing || orderOption === STOP) && (
                            <div>
                              {(!nextLiquidationPrice || nextLiquidationPrice.eq(liquidationPrice)) && (
                                <div>{`$${formatAmount(liquidationPrice, USD_DECIMALS, 2, true)}`}</div>
                              )}
                              {nextLiquidationPrice && !nextLiquidationPrice.eq(liquidationPrice) && (
                                <div>
                                  <div className="inline-block muted">
                                    ${formatAmount(liquidationPrice, USD_DECIMALS, 2, true)}
                                    <BsArrowRight className="transition-arrow" />
                                  </div>
                                  ${formatAmount(nextLiquidationPrice, USD_DECIMALS, 2, true)}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="sell_line"></div>
                    <div className="p_dtail">
                      <div className="dtail_lt font-weight-6">Size</div>
                      <div className="dtail_rt font-weight-6">
                        {position && position.size && fromAmount && (
                          <div className="g_flex">
                            <div className="f_size">
                              ${formatAmount(position.size, USD_DECIMALS, 2, true)}
                              <BsArrowRight />
                            </div>
                            ${formatAmount(position.size.sub(fromAmount), USD_DECIMALS, 2, true)}
                          </div>
                        )}
                        {position && position.size && !fromAmount && (
                          <div>${formatAmount(position.size, USD_DECIMALS, 2, true)}</div>
                        )}
                      </div>
                    </div>

                    <div className="p_dtail">
                      <div className="dtail_lt font-weight-6">Collateral</div>
                      <div className="dtail_rt font-weight-6">
                        {nextCollateral && !nextCollateral.eq(position.collateral) ? (
                          <div className="g_flex">
                            <div className="f_size">
                              ${formatAmount(position.collateral, USD_DECIMALS, 2, true)}
                              <BsArrowRight />
                            </div>
                            ${formatAmount(nextCollateral, USD_DECIMALS, 2, true)}
                          </div>
                        ) : (
                          `$${formatAmount(position.collateral, USD_DECIMALS, 4, true)}`
                        )}
                      </div>
                    </div>

                    {!keepLeverage && (
                      <div className="p_dtail">
                        <div className="dtail_lt font-weight-6">Leverage</div>
                        <div className="dtail_rt font-weight-6">
                          {isClosing && "-"}
                          {!isClosing && (
                            <div className="g_flex">
                              {!nextLeverage && <div>{formatAmount(position.leverage, 4, 2)}x</div>}
                              {nextLeverage && (
                                <div className="g_flex">
                                  <div className="f_size">
                                    {formatAmount(position.leverage, 4, 2)}x
                                    <BsArrowRight />
                                  </div>
                                  {formatAmount(nextLeverage, 4, 2)}x
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    <div className="sell_line"></div>

                    <div className="p_dtail">
                      <div className="dtail_lt font-weight-6">PnL</div>
                      <div className="dtail_rt font-weight-6">
                        {deltaStr} ({deltaPercentageStr})
                      </div>
                    </div>

                    <div className="p_dtail">
                      <div className="dtail_lt font-weight-6">Funding Fee</div>
                      <div className="dtail_rt font-weight-6">
                        {fundingFee.gt(0) ? (
                          <span>-${formatAmount(fundingFee, USD_DECIMALS, 2, true)}</span>
                        ) : (
                          <span>+${Math.abs(formatAmount(fundingFee, USD_DECIMALS, 2, true))}</span>
                        )}
                      </div>
                    </div>

                    <div className="p_dtail">
                      <div className="dtail_lt font-weight-6">Closing Fee</div>
                      <div className="dtail_rt font-weight-6">
                        {positionFee && positionFee.gt(0) && (
                          <span>-${formatAmount(positionFee, USD_DECIMALS, 2, true)}</span>
                        )}
                        {positionFee && positionFee.lt(0) && (
                          <span>+${Math.abs(formatAmount(positionFee, USD_DECIMALS, 2, true))}</span>
                        )}
                        {!positionFee && <span className="color5">-</span>}
                      </div>
                    </div>

                    <div className="p_dtail">
                      <div className="dtail_lt font-weight-6">Premium</div>
                      <div className="dtail_rt font-weight-6">
                        {position.pendingPremiumFee.eq(0) ? (
                          <span className="color5">-</span>
                        ) : position.pendingPremiumFee.gt(0) ? (
                          <span>-${formatAmount(position.pendingPremiumFee, USD_DECIMALS, 2, true)}</span>
                        ) : (
                          <span>+${Math.abs(formatAmount(position.pendingPremiumFee, USD_DECIMALS, 2, true))}</span>
                        )}
                      </div>
                    </div>

                    {orderOption === MARKET && (
                      <div className="p_dtail">
                        <div className="dtail_lt font-weight-6">Short-term Tax</div>
                        <div className="dtail_rt font-weight-6">
                          {position.termTax.eq(0) ? (
                            <span className="color5">-</span>
                          ) : position.termTax.gt(0) ? (
                            <span>-${formatAmount(position.termTax, USD_DECIMALS, 2, true)}</span>
                          ) : (
                            <span>+${Math.abs(formatAmount(position.termTax, USD_DECIMALS, 2, true))}</span>
                          )}
                        </div>
                      </div>
                    )}
                    {orderOption === MARKET && (
                      <div className="p_dtail">
                        <div className="dtail_lt font-weight-6">Execution Fee</div>
                        <div className="dtail_rt font-weight-6">
                          <Tooltip
                            handle={`${formatAmountFree(minExecutionFee, 18, 5)} ${nativeTokenSymbol}`}
                            position="right-top"
                            renderContent={() => {
                              return (
                                <>
                                  Network fee: {formatAmountFree(minExecutionFee, 18, 5)} {nativeTokenSymbol} ($
                                  {formatAmount(minExecutionFeeUSD, USD_DECIMALS, 2)})<br />
                                  <br />
                                  This is the network cost required to execute the decrease postion.{" "}
                                  <a
                                    // href="https://gmxio.gitbook.io/gmx/trading#execution-fee"
                                    href=""
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    More Info
                                  </a>
                                </>
                              );
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Box>
            </Box>
          </div>

          <div className="Exchange-swap-button-container closeBtnContainer">
            <button
              className="App-cta Exchange-swap-button app_closeb"
              onClick={onClickPrimary}
              disabled={!isPrimaryEnabled()}
            >
              {getPrimaryText()}
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
