import { ethers } from "ethers";
import { addresses } from "../configs/constants";
import { setAll } from "../helpers";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { IBaseAddressAsyncThunk } from "./interfaces";
import { abi as RewardRouterABI } from "../abis/RewardRouter.json";
import { abi as gEdeYieldDistributorABI } from "../abis/gEdeYieldDistributor.json";

export const loadAccountRewards = createAsyncThunk(
    "account/loadAccountRewards",
    async ({ networkID, provider, address, blockNumber }: IBaseAddressAsyncThunk) => {
        const signer = provider.getSigner()

        // stake EDE rewards
        const EdeYieldDistributorContract = new ethers.Contract(addresses[networkID].edeDistribution, gEdeYieldDistributorABI, provider)
        let rewards_ede = await EdeYieldDistributorContract.earned(address)
        const EusdYieldDistributorContract = new ethers.Contract(addresses[networkID].eusdDistribution, gEdeYieldDistributorABI, provider)
        let rewards_eusd = await EusdYieldDistributorContract.earned(address)
        // console.log("rewards_ede", rewards_ede, "rewards_eusd", rewards_eusd)
        // stake ELP rewards
        const RewardRouterContract = new ethers.Contract(addresses[networkID].RewardRouter, RewardRouterABI, signer)
        const address_elp1 = addresses[networkID].ELP
        let address_elp2 = addresses[networkID].ELP_2
        let address_elp1p = addresses[networkID].ELP_1p
        let claimableEdeList = await RewardRouterContract.claimableEDEList()
        // console.log("claimableEdeList", claimableEdeList)
        const claimableEdeAddress = claimableEdeList[0]
        const claimableEdeVal = claimableEdeList[1]
        const index = claimableEdeAddress.findIndex((item: string) => item == address_elp1)
        let claimableEdeArr = [ethers.utils.formatEther(claimableEdeVal[index])]
        if (networkID == 56) { //|| networkID == 97
            const index2 = claimableEdeAddress.findIndex((item: string) => item == address_elp2)
            const index3 = claimableEdeAddress.findIndex((item: string) => item == address_elp1p)
            claimableEdeArr = [ethers.utils.formatEther(claimableEdeVal[index3]), ethers.utils.formatEther(claimableEdeVal[index]), ethers.utils.formatEther(claimableEdeVal[index2])]
        }
        // console.log("claimableEdeArr", claimableEdeArr)
        let claimableEusdList = await RewardRouterContract.claimableEUSDList()
        const claimableEusdAddress = claimableEusdList[0]
        const claimableEusdVal = claimableEusdList[1]
        const key = claimableEusdAddress.findIndex((item: string) => item == address_elp1)
        let claimableEusdArr = [ethers.utils.formatEther(claimableEusdVal[key])]
        if (networkID == 56) {     // || networkID == 97
            const key2 = claimableEusdAddress.findIndex((item: string) => item == address_elp2)
            const key3 = claimableEusdAddress.findIndex((item: string) => item == address_elp1p)
            claimableEusdArr = [ethers.utils.formatEther(claimableEusdVal[key3]), ethers.utils.formatEther(claimableEusdVal[key]), ethers.utils.formatEther(claimableEusdVal[key2])]
        }
        // console.log("claimableEusdArr", claimableEusdArr)

        return {
            claimableEdeArr,
            claimableEusdArr,
            rewards_ede: ethers.utils.formatUnits(rewards_ede, 18),
            rewards_eusd: ethers.utils.formatUnits(rewards_eusd, 18),
        }
    },
);

interface IAccountRewardsSlice {
    loading: boolean;
}

const initialState: IAccountRewardsSlice = {
    loading: false,
};

const accountRewardsSlice = createSlice({
    name: "account",
    initialState,
    reducers: {
        fetchAccountSuccess(state, action) {
            setAll(state, action.payload);
        },
    },
    extraReducers: builder => {
        builder
            .addCase(loadAccountRewards.pending, state => {
                state.loading = true;
            })
            .addCase(loadAccountRewards.fulfilled, (state, action) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(loadAccountRewards.rejected, (state, { error }) => {
                state.loading = false;
            })
    },
});

const baseInfo = (state: RootState) => state.reward;
export default accountRewardsSlice.reducer;
export const { fetchAccountSuccess } = accountRewardsSlice.actions;
export const getAccountState = createSelector(baseInfo, accountRewards => accountRewards);
