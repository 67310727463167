import { ApolloClient, InMemoryCache } from "@apollo/client";

const chainID = process.env.REACT_APP_NETWORKID
const edeGraphClient_dev = createClient("https://taskgraph.ede.finance/subgraphs/name/aaronlux/ede-graph");
const edeGraphClient_prod = createClient("https://api.thegraph.com/subgraphs/name/metaverseblock/ede_graph_bsc");
const edeGraphClient_arb_dev = createClient("https://api.thegraph.com/subgraphs/name/metaverseblock/ede-graph-elp1-arbitrumgoerli")
const edeGraphClient_arb_prod = createClient("https://api.thegraph.com/subgraphs/name/metaverseblock/ede-graph_arbitrumone")

const edeGraphClient_daily_dev = createClient("https://taskgraph.ede.finance/subgraphs/name/ede_ranking")
const edeGraphClient_daily_prod = createClient("https://api.thegraph.com/subgraphs/name/metaverseblock/ede_ranking_elp2")
const edeGraphClient_daily_arb_dev = createClient("https://api.thegraph.com/subgraphs/name/metaverseblock/ede_ranking_arbitrumgoerli")
const edeGraphClient_daily_arb_prod = createClient("https://api.thegraph.com/subgraphs/name/metaverseblock/ede_ranking_arbitrumone")

const esbtGraphClient_dev = createClient("https://graph.ede.finance/subgraphs/name/esbt")
const esbtGraphClient_prod = createClient("https://api.thegraph.com/subgraphs/name/metaverseblock/esbt001")
const esbtGraphClient_arb_dev = createClient("https://api.thegraph.com/subgraphs/name/metaverseblock/ede_esbt_arbitrumgoerli")
const esbtGraphClient_arb_prod = createClient("https://api.thegraph.com/subgraphs/name/metaverseblock/ede_esbt_arbitrumone")

export const chainlinkClient = createClient("https://api.thegraph.com/subgraphs/name/deividask/chainlink");

export const communityGraph_trading = createClient("https://api.thegraph.com/subgraphs/name/metaverseblock/ede_ranking_arbitrumone_group")

function createClient(uri) {
  return new ApolloClient({
    uri,
    cache: new InMemoryCache(),
  });
}

export const getEdeGraphClient = (chainID) => {
  switch (chainID) {
    case 56:
      return edeGraphClient_prod
      break;
    case 97:
      return edeGraphClient_dev
      break;
    case 42161:
      return edeGraphClient_arb_prod
      break;
    case 421613:
      return edeGraphClient_arb_dev
      break;
    default:
      return edeGraphClient_prod
      break;
  }
}

export const getEdeGraphClient_daily = (chainID) => {
  switch (chainID) {
    case 56:
      return edeGraphClient_daily_prod
      break;
    case 97:
      return edeGraphClient_daily_dev
      break;
    case 42161:
      return edeGraphClient_daily_arb_prod
      break;
    case 421613:
      return edeGraphClient_daily_arb_dev
      break;
    default:
      return edeGraphClient_daily_prod
      break;
  }
}

export const getEsbtGraphClient = (chainID) => {
  switch (chainID) {
    case 56:
      return esbtGraphClient_prod
      break;
    case 97:
      return esbtGraphClient_dev
      break;
    case 42161:
      return esbtGraphClient_arb_prod
      break;
    case 421613:
      return esbtGraphClient_arb_dev
      break;
    default:
      return esbtGraphClient_prod
      break;
  }
}
