import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { ethers } from "ethers";
import { RootState } from "src/store";
import { addresses } from "../configs/constants";
import { setAll } from "../helpers";
import { IBaseAddressAsyncThunk } from "./interfaces";
import { abi as ElpABI } from "../abis/ELP.json";
import { abi as RewardTrackerABI } from "../abis/RewardTracker.json";
import { abi as EdeABI } from "../abis/EDE.json";
import { abi as EdeStakeABI } from "../abis/edeStake.json";
import { abi as IERC20ABI } from "../abis/IERC20.json";
import { PLACEHOLDER_ACCOUNT } from "src/helpers/Helpers";

export const loadAccountDetails = createAsyncThunk(
  "account/loadAccountDetails",
  async ({ networkID, provider, address }: IBaseAddressAsyncThunk) => {
    const signer = provider.getSigner();
    const EUSDContract = new ethers.Contract(addresses[networkID].EUSD, ElpABI, provider);
    const eusdBalance = await EUSDContract.balanceOf(address);
    // const EUSDStakingContract = new ethers.Contract(addresses[networkID].EUSDStaking, EUSDStakingABI, provider);
    // const warmupInfo = await EUSDStakingContract.warmupInfo(address);
    const eusdBalanceStaked = 0 //warmupInfo[0];

    const edeContract = new ethers.Contract(addresses[networkID].EDE, EdeABI, provider);
    const edeBalance = ethers.utils.formatUnits(await edeContract.balanceOf(address || PLACEHOLDER_ACCOUNT), 18);
    const gedeContract = new ethers.Contract(addresses[networkID].veStaker, EdeStakeABI, provider);
    const lockedInfo = await gedeContract.locked(address || PLACEHOLDER_ACCOUNT);
    const edeStaked = Number(ethers.utils.formatUnits(lockedInfo[0], 18));
    const gedeBalance = Number(ethers.utils.formatUnits(await gedeContract.balanceOfAtNow(address || PLACEHOLDER_ACCOUNT), 18));

    const elpContract1 = new ethers.Contract(addresses[networkID].ELP, ElpABI, provider);
    const rewardTrackerContract1 = new ethers.Contract(addresses[networkID].RewardTracker, RewardTrackerABI, provider);
    const elpBalance_1 = ethers.utils.formatEther(await elpContract1.balanceOf(address || PLACEHOLDER_ACCOUNT));
    const elpBalanceStaked_1 = ethers.utils.formatEther(await rewardTrackerContract1.balanceOf(address || PLACEHOLDER_ACCOUNT));
    let elpBalance_2 = ""
    let elpBalanceStaked_2 = ""
    if (networkID == 56 || networkID == 97) {
      const elpContract2 = new ethers.Contract(addresses[networkID].ELP_2, ElpABI, provider);
      const rewardTrackerContract2 = new ethers.Contract(addresses[networkID].RewardTracker_ELP_2, RewardTrackerABI, provider);
      elpBalance_2 = ethers.utils.formatEther(await elpContract2.balanceOf(address || PLACEHOLDER_ACCOUNT));
      elpBalanceStaked_2 = ethers.utils.formatEther(await rewardTrackerContract2.balanceOf(address || PLACEHOLDER_ACCOUNT));
    }

    const aEDEContract = new ethers.Contract(addresses[networkID].aEDE, IERC20ABI, provider);
    const balance_aEDE = await aEDEContract.balanceOf(address || PLACEHOLDER_ACCOUNT);

    return {
      gedeBalance,
      edeBalance,
      edeStaked,
      eusdBalance: ethers.utils.formatUnits(eusdBalance, 18),
      eusdBalanceStaked: ethers.utils.formatUnits(eusdBalanceStaked, 18),
      elpBalance_1,
      elpBalanceStaked_1,
      elpBalance_2,
      elpBalanceStaked_2,
      balance_aEDE: ethers.utils.formatEther(balance_aEDE),
    };
  },
);
interface IAccountSlice {
  loading: boolean;
}

const initialState: IAccountSlice = {
  loading: false,
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    fetchAccountSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadAccountDetails.pending, state => {
        state.loading = true;
      })
      .addCase(loadAccountDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadAccountDetails.rejected, (state, { error }) => {
        state.loading = false;
      });
  },
});

const baseInfo = (state: RootState) => state.account;
export default accountSlice.reducer;
export const { fetchAccountSuccess } = accountSlice.actions;
export const getAccountState = createSelector(baseInfo, account => account);
