import React, { useEffect, useState, useMemo, useCallback, forwardRef, useImperativeHandle } from "react";
import { Box, Slider, Button, Zoom } from "@material-ui/core";
import useSWR from "swr";
import { ethers } from "ethers";
import cx from "classnames";

import "./trade.scss";
import "./trade-m.scss";
import { getContractAddress } from "../../Addresses";
import { getTokens, getToken, getWhitelistedTokens, getTokenBySymbol } from "../../configs/Tokens";
import { useGetpositions } from "./hooks";
import {
  SWAP,
  LONG,
  SHORT,
  USD_DECIMALS,
  getExplorerUrl,
  helperToast,
  formatAmount,
  bigNumberify,
  getTokenInfo,
  fetcher,
  getPositionKey,
  useLocalStorageSerializeKey,
  useLocalStorageByChainId,
  useAccountOrders,
  getPageTitle,
  PLACEHOLDER_ACCOUNT,
} from "../../helpers/Helpers";
import { getPositions, getPositionQuery } from "./trade";
import { useWeb3Context } from "src/hooks";
import { getConstant } from "../../configs/getConstant";
import { approvePlugin, useMinExecutionFee, cancelMultipleOrders, useInfoTokens } from "../../Api";
import Reader from "../../abis/ReaderV2.json";
import VaultV2 from "../../abis/VaultV2.json";
import Router from "../../abis/Router.json";
import PositionReader from "../../abis/PositionReader.json";
import Tab from "../../components/Tab/Tab";
import Checkbox from "../../components/Checkbox/Checkbox";
import SwapBox from "../../components/Exchange/SwapBox";
import ExchangeBanner from "../../components/Exchange/ExchangeBanner";
import ExchangeTVChart, { getChartToken } from "../../components/Exchange/ExchangeTVChart";
import PositionsList from "../../components/Exchange/PositionsList";
import OrdersList from "../../components/Exchange/OrdersList";
import TradeHistory from "../../components/Exchange/TradeHistory";
import ExchangeWalletTokens from "../../components/Exchange/ExchangeWalletTokens";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  getOrderBookAddress,
  getPositionRouterAddress,
  getVaultAddress,
  getRouterAddress,
} from "src/helpers/elpAddress";

import { useVaultPriceFeedV2Fast } from "src/hooks/useContract";

const { AddressZero } = ethers.constants;

const notifications = {};

function pushSuccessNotification(chainID, message, e) {
  const { transactionHash } = e;
  const id = ethers.utils.id(message + transactionHash);
  if (notifications[id]) {
    return;
  }

  notifications[id] = true;

  const txUrl = getExplorerUrl(chainID) + "tx/" + transactionHash;
  helperToast.success(
    <div>
      {message}
      <a href={txUrl} target="_blank" rel="noopener noreferrer">
        View
      </a>
    </div>,
  );
}

function pushErrorNotification(chainID, message, e) {
  const { transactionHash } = e;
  const id = ethers.utils.id(message + transactionHash);
  if (notifications[id]) {
    return;
  }

  notifications[id] = true;

  const txUrl = getExplorerUrl(chainID) + "tx/" + transactionHash;
  helperToast.error(
    <div>
      {message}
      <a href={txUrl} target="_blank" rel="noopener noreferrer">
        View
      </a>
    </div>,
  );
}

export const Trade_arb = forwardRef((props, ref) => {
  const {
    savedIsPnlInLeverage,
    setSavedIsPnlInLeverage,
    savedShowPnlAfterFees,
    savedSlippageAmount,
    pendingTxns,
    setPendingTxns,
    savedShouldShowPositionLines,
    setSavedShouldShowPositionLines,
    savedShouldDisableOrderValidation,
  } = props;
  const [showBanner, setShowBanner] = useLocalStorageSerializeKey("showBanner", true);
  const [bannerHidden, setBannerHidden] = useLocalStorageSerializeKey("bannerHidden", null);

  const [pendingPositions, setPendingPositions] = useState({});
  const [updatedPositions, setUpdatedPositions] = useState({});
  const [orderBookApproved, setOrderBookApproved] = useState(false);

  const hideBanner = () => {
    const hiddenLimit = new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000);
    setBannerHidden(hiddenLimit);
    setShowBanner(false);
  };

  useEffect(() => {
    if (new Date() > new Date("2021-11-30")) {
      setShowBanner(false);
    } else {
      if (bannerHidden && new Date(bannerHidden) > new Date()) {
        setShowBanner(false);
      } else {
        setBannerHidden(null);
        setShowBanner(true);
      }
    }
  }, [showBanner, bannerHidden, setBannerHidden, setShowBanner]);

  const { chainID, connected, address, provider } = useWeb3Context();
  const currentAccount = address;

  const tokens = getTokens(chainID);

  const [elpName, setElpName] = useState("ELP-1");
  const [tokenDecimals, setTokenDecimals] = useState(0);

  const nativeTokenAddress = getContractAddress(chainID, "NATIVE_TOKEN");
  const vaultAddress = getVaultAddress(chainID, elpName);
  const positionRouterAddress = getPositionRouterAddress(chainID, elpName);
  const orderBookAddress = getOrderBookAddress(chainID, elpName);
  const routerAddress = getRouterAddress(chainID, elpName);
  const readerAddress = getContractAddress(chainID, "Reader");
  const positionReaderAddress = getContractAddress(chainID, "PositionReader");

  const whitelistedTokens = getWhitelistedTokens(chainID);
  const whitelistedTokenAddresses = whitelistedTokens.map(token => token.address);
  const positionQuery = getPositionQuery(whitelistedTokens, nativeTokenAddress);
  // console.log("positionQuery---", positionQuery);
  const defaultCollateralSymbol = getConstant(chainID, "defaultCollateralSymbol");
  const defaultTokenSelection = useMemo(
    () => ({
      [SWAP]: {
        from: AddressZero,
        to: getTokenBySymbol(chainID, defaultCollateralSymbol).address,
      },
      [LONG]: {
        from: AddressZero,
        to: AddressZero,
      },
      [SHORT]: {
        from: getTokenBySymbol(chainID, defaultCollateralSymbol).address,
        to: AddressZero,
      },
    }),
    [chainID, defaultCollateralSymbol],
  );
  const [tokenSelection, setTokenSelection] = useLocalStorageByChainId(
    chainID,
    "Exchange-token-selection-v2",
    defaultTokenSelection,
  );

  // const [tokenSelection, setTokenSelection] = useState({
  //   "Long": { from: getTokenBySymbol(chainID, "BUSD").address, to: '0x0000000000000000000000000000000000000000' },
  //   "Short": { from: 'getTokenBySymbol(chainID, "BUSD").address', to: '0x0000000000000000000000000000000000000000' },
  //   "Swap": { from: '0x0000000000000000000000000000000000000000', to: 'getTokenBySymbol(chainID, "BUSD").NATIVE_TOKEN' }
  // })
  const [swapOption, setSwapOption] = useLocalStorageByChainId(chainID, "Swap-option-v2", LONG);
  const fromTokenAddress = tokenSelection[swapOption].from;
  const toTokenAddress = tokenSelection[swapOption].to;
  useEffect(() => {
    const arr = tokens.filter(token => token.address == fromTokenAddress);
    arr[0].isElp1 ? setElpName("ELP-1") : setElpName("ELP-2");
  }, fromTokenAddress);
  useEffect(() => {
    const arr = tokens.filter(token => token.address == toTokenAddress);
    setTokenDecimals(arr[0].tokenDecimals);
    arr[0].isElp1 ? setElpName("ELP-1") : setElpName("ELP-2");
  }, toTokenAddress);

  const setFromTokenAddress = useCallback(
    (selectedSwapOption, address) => {
      const newTokenSelection = JSON.parse(JSON.stringify(tokenSelection));
      newTokenSelection[selectedSwapOption].from = address;
      setTokenSelection(newTokenSelection);
    },
    [tokenSelection, setTokenSelection],
  );

  const setToTokenAddress = useCallback(
    (selectedSwapOption, address) => {
      const newTokenSelection = JSON.parse(JSON.stringify(tokenSelection));
      newTokenSelection[selectedSwapOption].to = address;
      if (selectedSwapOption === LONG || selectedSwapOption === SHORT) {
        newTokenSelection[LONG].to = address;
        newTokenSelection[SHORT].to = address;
      }
      setTokenSelection(newTokenSelection);
    },
    [tokenSelection, setTokenSelection],
  );

  const setMarket = (selectedSwapOption, toTokenAddress) => {
    setSwapOption(selectedSwapOption);
    const newTokenSelection = JSON.parse(JSON.stringify(tokenSelection));
    newTokenSelection[selectedSwapOption].to = toTokenAddress;
    if (selectedSwapOption === LONG || selectedSwapOption === SHORT) {
      newTokenSelection[LONG].to = toTokenAddress;
      newTokenSelection[SHORT].to = toTokenAddress;
    }
    setTokenSelection(newTokenSelection);
  };

  const [isConfirming, setIsConfirming] = useState(false);
  const [isPendingConfirmation, setIsPendingConfirmation] = useState(false);

  const tokenAddresses = tokens.map(token => token.address);
  const { data: tokenBalances1 } = useSWR(
    connected && [connected + "ELP-1", chainID, readerAddress, "getTokenBalances", address],
    {
      fetcher: fetcher(provider, Reader, [tokenAddresses]),
    },
  );
  /* ELP-1 & ELP-2 */
  const { data: positionData, error: positionDataError } = useSWR(
    connected && [connected + "ELP-1", chainID, readerAddress, "getPositions", vaultAddress, address],
    {
      fetcher: fetcher(provider, Reader, [
        positionQuery.collateralTokens,
        positionQuery.indexTokens,
        positionQuery.isLong,
      ]),
      refreshInterval: 3000,
    },
  );
  // console.log("positionData", positionData);
  const positionsDataIsLoading = connected && !positionData;

  // const { data: fundingRateInfo1 } = useSWR([connected + "ELP-1", chainID, readerAddress, "getFundingRates"], {
  //   fetcher: fetcher(provider, Reader, [vaultAddress, nativeTokenAddress, whitelistedTokenAddresses]),
  // });
  const { data: fundingRateInfo1 } = useSWR([connected + "ELP-1", chainID, positionReaderAddress, "getTokenInfo"], {
    fetcher: fetcher(provider, PositionReader, [vaultAddress, whitelistedTokenAddresses]),
  });
  const { data: totalTokenWeights } = useSWR(
    [`Exchange:totalTokenWeights:${connected}` + "elpName", chainID, vaultAddress, "totalTokenWeights"],
    {
      fetcher: fetcher(provider, VaultV2),
    },
  );

  const tokensArr = useSelector(state => {
    return state.app.tokensArr || [];
  });

  const [usdxSupply, setUsdxSupply] = useState(0);
  useEffect(() => {
    if (tokensArr.length > 0) {
      var val = elpName == "ELP-1" ? tokensArr[0].usdxSupply : tokensArr[1].usdxSupply;
      setUsdxSupply(val);
    }
  }, [tokensArr, elpName]);

  const { data: orderBookApproved1 } = useSWR(
    connected && [connected + "ELP-1", chainID, routerAddress, "approvedPlugins", address, orderBookAddress],
    {
      fetcher: fetcher(provider, Router),
    },
  );
  useEffect(() => {
    const isApproved = orderBookApproved1;
    setOrderBookApproved(isApproved);
  }, [elpName, orderBookApproved1]);

  const { data: positionRouterApproved } = useSWR(
    connected && [connected, chainID, routerAddress, "approvedPlugins", address, positionRouterAddress],
    {
      fetcher: fetcher(provider, Router),
    },
  );

  let { infoTokens } = useInfoTokens("ELP-1", provider, chainID, connected, tokenBalances1, fundingRateInfo1);
  const currInfoTokens = infoTokens;

  const { minExecutionFee, minExecutionFeeUSD, minExecutionFeeErrorMessage } = useMinExecutionFee(
    elpName,
    provider,
    connected,
    chainID,
    infoTokens,
  );

  useEffect(() => {
    const fromToken = getTokenInfo(infoTokens, fromTokenAddress);
    const toToken = getTokenInfo(infoTokens, toTokenAddress);
    let selectedToken = getChartToken(swapOption, fromToken, toToken, chainID);
    let currentTokenPriceStr = formatAmount(selectedToken.maxPrice, USD_DECIMALS, 2, true);
    let title = getPageTitle(currentTokenPriceStr + ` | ${selectedToken.symbol}${selectedToken.isStable ? "" : "USD"}`);
    document.title = title;
  }, [tokenSelection, swapOption, infoTokens, chainID, fromTokenAddress, toTokenAddress]);

  const uniqueArr = (arr, val) => {
    const res = new Map();
    return arr.filter(item => !res.has(item[val]) && res.set(item[val], 1));
  };

  const positionsMap = [];
  const positions = useGetpositions(infoTokens, pendingPositions);

  useImperativeHandle(ref, () => ({
    onUpdatePosition(key, size, collateral, averagePrice, entryFundingRate, reserveAmount, realisedPnl) {
      for (let i = 0; i < positions.length; i++) {
        const position = positions[i];
        if (position.contractKey === key) {
          updatedPositions[position.key] = {
            size,
            collateral,
            averagePrice,
            entryFundingRate,
            reserveAmount,
            realisedPnl,
            updatedAt: Date.now(),
          };
          setUpdatedPositions({ ...updatedPositions });
          break;
        }
      }
    },
    onClosePosition(key, size, collateral, averagePrice, entryFundingRate, reserveAmount, realisedPnl, e) {
      for (let i = 0; i < positions.length; i++) {
        const position = positions[i];
        if (position.contractKey === key) {
          updatedPositions[position.key] = {
            size: bigNumberify(0),
            collateral: bigNumberify(0),
            averagePrice,
            entryFundingRate,
            reserveAmount,
            realisedPnl,
            updatedAt: Date.now(),
          };
          setUpdatedPositions({ ...updatedPositions });
          break;
        }
      }
    },

    onIncreasePosition(key, address, collateralToken, indexToken, collateralDelta, sizeDelta, isLong, price, fee, e) {
      if (address !== currentAccount) {
        return;
      }

      const indexTokenItem = getToken(chainID, indexToken);
      const tokenSymbol = indexTokenItem.isWrapped ? getConstant(chainID, "nativeTokenSymbol") : indexTokenItem.symbol;

      let message;
      if (sizeDelta.eq(0)) {
        message = `Deposited ${formatAmount(collateralDelta, USD_DECIMALS, 2, true)} USD into ${tokenSymbol} ${
          isLong ? "Long" : "Short."
        }`;
      } else {
        message = `Increased ${tokenSymbol} ${isLong ? "Long" : "Short"}, +${formatAmount(
          sizeDelta,
          USD_DECIMALS,
          2,
          true,
        )} USD.`;
      }

      pushSuccessNotification(chainID, message, e);
    },

    onDecreasePosition(key, address, collateralToken, indexToken, collateralDelta, sizeDelta, isLong, price, fee, e) {
      if (address !== currentAccount) {
        return;
      }

      const indexTokenItem = getToken(chainID, indexToken);
      const tokenSymbol = indexTokenItem.isWrapped ? getConstant(chainID, "nativeTokenSymbol") : indexTokenItem.symbol;

      let message;
      if (sizeDelta.eq(0)) {
        message = `Withdrew ${formatAmount(collateralDelta, USD_DECIMALS, 2, true)} USD from ${tokenSymbol} ${
          isLong ? "Long" : "Short"
        }.`;
      } else {
        message = `Decreased ${tokenSymbol} ${isLong ? "Long" : "Short"}, -${formatAmount(
          sizeDelta,
          USD_DECIMALS,
          2,
          true,
        )} USD.`;
      }

      pushSuccessNotification(chainID, message, e);
    },

    onCancelIncreasePosition(
      account = { address },
      path,
      indexToken,
      amountIn,
      minOut,
      sizeDelta,
      isLong,
      acceptablePrice,
      executionFee,
      blockGap,
      timeGap,
      e,
    ) {
      if (address !== currentAccount) {
        return;
      }
      const indexTokenItem = getToken(chainID, indexToken);
      const tokenSymbol = indexTokenItem.isWrapped ? getConstant(chainID, "nativeTokenSymbol") : indexTokenItem.symbol;

      const message = `Could not increase ${tokenSymbol} ${
        isLong ? "Long" : "Short"
      } within the allowed slippage, you can adjust the allowed slippage in the settings on the top right of the page.`;

      pushErrorNotification(chainID, message, e);

      const key = getPositionKey(address, path[path.length - 1], indexToken, isLong);
      pendingPositions[key] = {};
      setPendingPositions({ ...pendingPositions });
    },

    onCancelDecreasePosition(
      account = { address },
      path,
      indexToken,
      collateralDelta,
      sizeDelta,
      isLong,
      receiver,
      acceptablePrice,
      minOut,
      executionFee,
      blockGap,
      timeGap,
      e,
    ) {
      if (address !== currentAccount) {
        return;
      }
      const indexTokenItem = getToken(chainID, indexToken);
      const tokenSymbol = indexTokenItem.isWrapped ? getConstant(chainID, "nativeTokenSymbol") : indexTokenItem.symbol;

      const message = `Could not decrease ${tokenSymbol} ${
        isLong ? "Long" : "Short"
      } within the allowed slippage, you can adjust the allowed slippage in the settings on the top right of the page.`;

      pushErrorNotification(chainID, message, e);

      const key = getPositionKey(address, path[path.length - 1], indexToken, isLong);
      pendingPositions[key] = {};
      setPendingPositions({ ...pendingPositions });
    },
  }));

  const flagOrdersEnabled = true;
  // const [orders] = useAccountOrders(elpName, flagOrdersEnabled, address);
  const ordersArr1 = useAccountOrders("ELP-1", flagOrdersEnabled, address);
  let orders = ordersArr1[0];
  if (chainID == 97 || chainID == 56) {
    const ordersArr2 = useAccountOrders("ELP-2", flagOrdersEnabled, address);
    orders = [...ordersArr1[0], ...ordersArr2[0]];
  }
  // console.log("orders", orders);

  const [isWaitingForPluginApproval, setIsWaitingForPluginApproval] = useState(false);
  const [isWaitingForPositionRouterApproval, setIsWaitingForPositionRouterApproval] = useState(false);
  const [isPluginApproving, setIsPluginApproving] = useState(false);
  const [isPositionRouterApproving, setIsPositionRouterApproving] = useState(false);
  const [isCancelMultipleOrderProcessing, setIsCancelMultipleOrderProcessing] = useState(false);
  const [cancelOrderIdList, setCancelOrderIdList] = useState([]);

  const onMultipleCancelClick = useCallback(
    async function () {
      setIsCancelMultipleOrderProcessing(true);
      try {
        const tx = await cancelMultipleOrders(elpName, chainID, provider, cancelOrderIdList, {
          successMsg: "Orders cancelled.",
          failMsg: "Cancel failed.",
          sentMsg: "Cancel submitted.",
          pendingTxns,
          setPendingTxns,
        });
        const receipt = await tx.wait();
        if (receipt.status === 1) {
          setCancelOrderIdList([]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsCancelMultipleOrderProcessing(false);
      }
    },
    [
      chainID,
      provider,
      pendingTxns,
      setPendingTxns,
      setCancelOrderIdList,
      cancelOrderIdList,
      setIsCancelMultipleOrderProcessing,
    ],
  );

  const approveOrderBook = () => {
    setIsPluginApproving(true);
    return approvePlugin(chainID, orderBookAddress, {
      elpName,
      library: provider,
      pendingTxns,
      setPendingTxns,
      sentMsg: "Enable orders sent.",
      failMsg: "Enable orders failed.",
    })
      .then(() => {
        setIsWaitingForPluginApproval(true);
      })
      .finally(() => {
        setIsPluginApproving(false);
      });
  };

  const approvePositionRouter = ({ sentMsg, failMsg }) => {
    setIsPositionRouterApproving(true);
    return approvePlugin(chainID, positionRouterAddress, {
      elpName,
      library: provider,
      pendingTxns,
      setPendingTxns,
      sentMsg,
      failMsg,
    })
      .then(() => {
        setIsWaitingForPositionRouterApproval(true);
      })
      .finally(() => {
        setIsPositionRouterApproving(false);
      });
  };

  const LIST_SECTIONS = ["Positions", flagOrdersEnabled ? "Orders" : undefined, "Trades"].filter(Boolean);
  let [listSection, setListSection] = useLocalStorageByChainId(chainID, "List-section-v2", LIST_SECTIONS[0]);
  const LIST_SECTIONS_LABELS = {
    Orders: orders.length ? `Orders (${orders.length})` : undefined,
    Positions: positions?.length ? `Positions (${positions.length})` : undefined,
  };
  if (!LIST_SECTIONS.includes(listSection)) {
    listSection = LIST_SECTIONS[0];
  }
  if (!getToken(chainID, toTokenAddress)) {
    return null;
  }
  const renderCancelOrderButton = () => {
    const orderText = cancelOrderIdList.length > 1 ? "orders" : "order";
    if (cancelOrderIdList.length === 0) return;
    return (
      <button
        className="muted font-base cancel-order-btn"
        disabled={isCancelMultipleOrderProcessing}
        type="button"
        onClick={onMultipleCancelClick}
      >
        Cancel {cancelOrderIdList.length} {orderText}
      </button>
    );
  };
  const getListSection = () => {
    return (
      <Box width={"100%"} display={"flex"} flexDirection={"column"} flex={"1"}>
        <div className="Exchange-list-tab-container">
          <Tab
            options={LIST_SECTIONS}
            optionLabels={LIST_SECTIONS_LABELS}
            option={listSection}
            onChange={section => setListSection(section)}
            type="inline"
            className="Exchange-list-tabs"
          />
          <div className="align-right Exchange-should-show-position-lines">
            {renderCancelOrderButton()}
            <Checkbox
              isChecked={savedShouldShowPositionLines}
              setIsChecked={setSavedShouldShowPositionLines}
              className={cx("muted chart-positions", { active: savedShouldShowPositionLines })}
            >
              <span>Chart positions</span>
            </Checkbox>
          </div>
        </div>
        <div className="list_section">
          {listSection === "Positions" && (
            <PositionsList
              positionsDataIsLoading={positionsDataIsLoading}
              pendingPositions={pendingPositions}
              setPendingPositions={setPendingPositions}
              setListSection={setListSection}
              setIsWaitingForPluginApproval={setIsWaitingForPluginApproval}
              setIsWaitingForPositionRouterApproval={setIsWaitingForPositionRouterApproval}
              approveOrderBook={approveOrderBook}
              approvePositionRouter={approvePositionRouter}
              isPluginApproving={isPluginApproving}
              isPositionRouterApproving={isPositionRouterApproving}
              isWaitingForPluginApproval={isWaitingForPluginApproval}
              isWaitingForPositionRouterApproval={isWaitingForPositionRouterApproval}
              orderBookApproved={orderBookApproved}
              positionRouterApproved={positionRouterApproved}
              positions={positions}
              positionsMap={positionsMap}
              infoTokens={infoTokens}
              active={connected}
              account={address}
              library={provider}
              pendingTxns={pendingTxns}
              setPendingTxns={setPendingTxns}
              flagOrdersEnabled={flagOrdersEnabled}
              savedIsPnlInLeverage={savedIsPnlInLeverage}
              chainId={chainID}
              nativeTokenAddress={nativeTokenAddress}
              setMarket={setMarket}
              orders={orders}
              showPnlAfterFees={savedShowPnlAfterFees}
              minExecutionFee={minExecutionFee}
              minExecutionFeeUSD={minExecutionFeeUSD}
              minExecutionFeeErrorMessage={minExecutionFeeErrorMessage}
            />
          )}
          {listSection === "Orders" && (
            <OrdersList
              elpName={elpName}
              account={address}
              active={connected}
              library={provider}
              pendingTxns={pendingTxns}
              setPendingTxns={setPendingTxns}
              infoTokens={infoTokens}
              positionsMap={positionsMap}
              chainId={chainID}
              orders={orders}
              totalTokenWeights={totalTokenWeights}
              usdxSupply={usdxSupply}
              savedShouldDisableOrderValidation={savedShouldDisableOrderValidation}
              cancelOrderIdList={cancelOrderIdList}
              setCancelOrderIdList={setCancelOrderIdList}
            />
          )}
          {listSection === "Trades" && (
            <TradeHistory
              account={address}
              forSingleAccount={true}
              infoTokens={infoTokens}
              getTokenInfo={getTokenInfo}
              chainID={chainID}
              nativeTokenAddress={nativeTokenAddress}
            />
          )}
        </div>
      </Box>
    );
  };

  const onSelectWalletToken = token => {
    setFromTokenAddress(swapOption, token.address);
  };

  const renderChart = () => {
    return (
      <ExchangeTVChart
        elpName={elpName}
        fromTokenAddress={fromTokenAddress}
        toTokenAddress={toTokenAddress}
        tokenDecimals={tokenDecimals}
        infoTokens={infoTokens}
        swapOption={swapOption}
        chainId={chainID}
        positions={positions}
        savedShouldShowPositionLines={savedShouldShowPositionLines}
        orders={orders}
        setToTokenAddress={setToTokenAddress}
        setFromTokenAddress={setFromTokenAddress}
      />
    );
  };

  return (
    <Box className="trade-view">
      <Box className="" height={"100%"}>
        <div className="Exchange ">
          {showBanner && <ExchangeBanner hideBanner={hideBanner} />}
          <div className="Exchange-content">
            <div className="Exchange-left">
              {renderChart()}
              <div className="Exchange-lists large">{getListSection()}</div>
            </div>
            <div className="Exchange-right">
              <SwapBox
                elpName={elpName}
                pendingPositions={pendingPositions}
                setPendingPositions={setPendingPositions}
                setIsWaitingForPluginApproval={setIsWaitingForPluginApproval}
                setIsWaitingForPositionRouterApproval={setIsWaitingForPositionRouterApproval}
                approveOrderBook={approveOrderBook}
                approvePositionRouter={approvePositionRouter}
                isPluginApproving={isPluginApproving}
                isPositionRouterApproving={isPositionRouterApproving}
                isWaitingForPluginApproval={isWaitingForPluginApproval}
                isWaitingForPositionRouterApproval={isWaitingForPositionRouterApproval}
                orderBookApproved={orderBookApproved}
                positionRouterApproved={positionRouterApproved}
                orders={orders}
                flagOrdersEnabled={flagOrdersEnabled}
                chainId={chainID}
                infoTokens={infoTokens}
                currInfoTokens={currInfoTokens}
                positionsMap={positionsMap}
                fromTokenAddress={fromTokenAddress}
                setFromTokenAddress={setFromTokenAddress}
                toTokenAddress={toTokenAddress}
                setToTokenAddress={setToTokenAddress}
                swapOption={swapOption}
                setSwapOption={setSwapOption}
                pendingTxns={pendingTxns}
                setPendingTxns={setPendingTxns}
                tokenSelection={tokenSelection}
                setTokenSelection={setTokenSelection}
                isConfirming={isConfirming}
                setIsConfirming={setIsConfirming}
                isPendingConfirmation={isPendingConfirmation}
                setIsPendingConfirmation={setIsPendingConfirmation}
                savedIsPnlInLeverage={savedIsPnlInLeverage}
                setSavedIsPnlInLeverage={setSavedIsPnlInLeverage}
                nativeTokenAddress={nativeTokenAddress}
                savedSlippageAmount={savedSlippageAmount}
                totalTokenWeights={totalTokenWeights}
                usdxSupply={usdxSupply}
                savedShouldDisableOrderValidation={savedShouldDisableOrderValidation}
                minExecutionFee={minExecutionFee}
                minExecutionFeeUSD={minExecutionFeeUSD}
                minExecutionFeeErrorMessage={minExecutionFeeErrorMessage}
              />
              <div className="Exchange-wallet-tokens">
                <div className="Exchange-wallet-tokens-content">
                  <ExchangeWalletTokens tokens={tokens} infoTokens={infoTokens} onSelectToken={onSelectWalletToken} />
                </div>
              </div>
            </div>
            <div className="Exchange-lists small">{getListSection()}</div>
          </div>
        </div>
      </Box>
    </Box>
  );
});
